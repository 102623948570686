import {Line} from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import "chartjs-plugin-annotation";
import "./AdminStatistics.css";
import UpcomingTest from "../../UpcomingTest/Admin/AdminUpcomingTest";
// import TestCase from "../TestCase/TestCase";

Chart.register(CategoryScale);

let monthNames = [
  "Jan, 2023",
  "Feb, 2023",
  "Mar, 2023",
  "April, 2023",
  "May, 2023",
  "Jun , 2023",
 
];

const data = {
  labels: [...monthNames],
  datasets: [
    {
      fill: false,
      label: "Passed",
      lineTension: 0.2,
      backgroundColor: "#7EB965",
      borderColor: "#7EB965",
      borderWidth: 1,
      pointBorderColor: "#7EB965",
      pointBackgroundColor: "#7EB965",
      pointHoverRadius: 1,
      pointHoverBackgroundColor: "#7EB965",
      pointHoverBorderColor: "#7EB965",
      pointHoverBorderWidth: 7,
      pointRadius: 0,
      pointHitRadius: 30,
      data: [80, 75, 40, 55, 28, 20],
    },

    {
      fill: false,
      label: "Failed",
      lineTension: 0.2,
      backgroundColor: "#DC3545",
      borderColor: "#DC3545",
      borderWidth: 1,
      pointBorderColor: "#DC3545",
      pointBackgroundColor: "#DC3545",
      pointHoverRadius: 1,
      pointHoverBackgroundColor: "#DC3545",
      pointHoverBorderColor: "#DC3545",
      pointHoverBorderWidth: 7,
      pointRadius: 0,
      pointHitRadius: 30,
      data: [50, 10, 70, 45, 45, 12],
    },
    {
      fill: false,
      label: "Test Suites",
      lineTension: 0.2,
      backgroundColor: "#805382",
      borderColor: "#805382",
      borderWidth: 1,
      pointBorderColor: "#805382",
      pointBackgroundColor: "#805382",
      pointHoverRadius: 1,
      pointHoverBackgroundColor: "#805382",
      pointHoverBorderColor: "#805382",
      pointHoverBorderWidth: 7,
      pointRadius: 0,
      pointHitRadius: 30,
      data: [100, 95, 70, 45, 25, 15],
    },
    {
      fill: false,
      label: "Test Cases",
      lineTension: 0.2,
      backgroundColor: "#E0A800",
      borderColor: "#E0A800",
      borderWidth: 1,
      pointBorderColor: "#E0A800",
      pointBackgroundColor: "#E0A800",
      pointHoverRadius: 1,
      pointHoverBackgroundColor: "#E0A800",
      pointHoverBorderColor: "#E0A800",
      pointHoverBorderWidth: 7,
      pointRadius: 0,
      pointHitRadius: 30,
      data: [90, 85, 60, 55, 25, 15],
    },
  ],
};

let line = [
  {
    type: "line",
    mode: "vertical",
    scaleID: "y-axis-0",
    value: 2000,
    borderColor: "#2984c5",
    borderWidth: 1,
  },
];

const options = {
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 12,
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          let label = "";
          if (context.parsed.y) {
            label = ` ${context.parsed.y}% ${context.dataset.label}`;
          }
          return label;
        },
      },
      usePointStyle: true,
      boxWidth: 8,
      displayColors: true,
      backgroundColor: "#5D3B5E",
      mode: "index",
      intersect: false,
      caretSize: 6,
      yPadding: 10,
      xPadding: 20,
      borderWidth: 0,
      titleFontSize: 16,
    },

    annotation: {
      annotations: line,
    },

    responsive: false,
  },
  scales: {
    y: {
      ticks: {
        display: false,
      },
      grid: {
        display: true,
        borderDash: [8, 4],
        drawBorder: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};

function Statistics() {
  return (
    <div className="ad-stat-container">
      <div className="ad-head_title">
        <span>Statistics</span>
        <span>Upcoming Test Schedule</span>
      </div>
      <div className="ad-row-stat">
        <div className="col ad-line_chart">
          <Line data={data} options={options} width={959.5} height={335} />
        </div>
        <div className="col">
          <UpcomingTest />
        </div>
      </div>
    </div>
  );
}

export default Statistics;
