import React from "react";
import Table from "react-bootstrap/Table";
import "./ReportTable.css";
// import { API } from "./../../../../global";
// import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import Sidebar from "../../Layout/Sidebar/Sidebar";
import { API } from "../../../../global";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Header from "../../../Layout/Navbar/Navbar";
import { useEffect } from "react";
import moment from "moment";

// import { Oval } from "react-loader-spinner";

function Report() {
  const navigate = useNavigate();

  const { proId } = useParams();
  const [applist, setApplist] = useState([]);
  const [runLogList, setRunLogList] = useState([]);
  const [app_selected, setApp_selected] = useState("");
  // const [all_cases, setAll_cases] = useState([]);
  // const [run_log_status, setRunLog] = useState([]);
  const [testsuite_selected, setTestsuite_selected] = useState("");
  const [testsuitelist, setTestsuitelist] = useState([]);


  const project_id = localStorage.getItem("project-id")
  const app_id = localStorage.getItem('app_id')
  const app_name = localStorage.getItem("app-id");

  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/qe/testsuite/report/table/${project_id}`);
    }
  };

  useEffect(() => {
    get_project_id();
    if(app_id){
        getApplication();
        getTestsuitesByAppId(app_id);
    }else{
        getApplication();
        getTestsuitesByProjectId();
    }
    // getRunLog();
    // eslint-disable-next-line
  }, [proId]);


  const getApplication = () => {
    setApplist([]);
    fetch(`${API}/admin/application/${project_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        app.data.length > 0 ? setApplist(app.data) : setApplist([]);
      })
  
  };

  const getTestsuitesByProjectId = () => {
    setApp_selected("");
    fetch(
      `${API}/tests/testsuite/application/?project_id=${project_id}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    ) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => { console.log(ts);
        setTestsuitelist(ts.data);
      })
  };

  const getTestsuitesByAppId = (appid) => {
    // setLoading(true);
    setTestsuitelist([])
    fetch(
      `${API}/tests/testsuite/application/?project_id=${project_id}&application_id=${appid}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    ) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        setTestsuitelist(ts.data);
      })
    // .then(()=> setLoading(false))
  };


  const getRunLog = (id) => {
    fetch(`${API}/tests/testsuites/report/runlog/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => { 
        console.log(pro.data);
        setRunLogList(pro.data);
      });
  };

  return (
    <div className=" overflow-hidden">
      <Header />
     
     <div className="reportList_testsuite">
      <Container fluid>
        <Row className="report_test-row">
          <Col>
            <div className="d-flex first-head justify-content-start">
              Test Suite Execution Report List
            </div>
          </Col>

          <Col md={7}>
            <div className="d-flex justify-content-end report-icons me-4">
              <div className="btn-group">
                <label className="TC-App position-absolute">Application</label>
                <button
                  type="button"
                  className="TC-bulk-btn-TCR me-3 dropdown-toggle"
                  data-bs-toggle="dropdown"
                  size="sm"
                  aria-expanded="false"
                >
                  {app_selected ? app_selected : app_name ? app_name : '- - Select - -'}
                </button>
                <ul className="dropdown-menu dScroll">
                  {applist.length === 0 ? "No Application Found"
                    : applist.map((app, i) => {
                      return (
                        <li
                          key={i}
                          className="dropdown-item"
                          onClick={() => {
                            getTestsuitesByAppId(app._id)
                            setApp_selected(app.name);
                            localStorage.setItem("app-id", app.name);
                            localStorage.setItem("app_id", app._id)
                          }}
                        >
                          {app.name}
                        </li>
                      )
                    })}
                </ul>
              </div>

              <div className="btn-group">
                <label className="TC-suite position-absolute">Test Suite</label>
                <button
                  type="button"
                  className="TC-bulk-btn-TSR me-3 dropdown-toggle"
                  data-bs-toggle="dropdown"
                  size="sm"
                  aria-expanded="false"
                >
                  {testsuite_selected ? testsuite_selected : "- - Select - -"}
                </button>
                <ul className="dropdown-menu dScroll">
                  {testsuitelist.length === 0 ? "No Testsuite Found"
                    : testsuitelist.map((test, i) => {
                      return (
                        <li
                          key={i}
                          className="dropdown-item"
                          onClick={() => {
                            getRunLog(test._id)
                            setTestsuite_selected(test.name)
                          }}
                        >
                          {test.name}
                        </li>
                      )
                    })}

                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
      {runLogList.length > 0 ? (
        <Table className="reportList-table">
          <thead
            style={{
              backgroundColor: "#E6C6E840",
              color: "#805382",
            }}
          >
            <tr>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Execution Time
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Environment
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Executed By
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Status
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Instant/Scheduled
              </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {runLogList.map((data, i) => (
              <tr style={{ height: "10px" }}>
                <td>{moment(data.updated_at).format("D MMM h:mm a")}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/qe/testReport/${data._id}`)}
                >
                  {data.test_suite_id.environment}
                </td>
                <td>{data.updated_by.Ad_username}</td>
                <td
                  style={{
                    color:
                      data.test_suite_id.last_run_status === "failed"
                        ? "#DC3545"
                        : "#7EB965",
                    fontWeight: "bold",
                  }}
                >
                  {data.test_suite_id.last_run_status}
                </td>
                <td>Instant</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) :  applist.length === 0 ? (
        <label style={{ marginTop: "20%",marginLeft:"45%" }}>Please Select an Application</label>
      ) : runLogList.length === 0 && testsuitelist.length === 0 ? 
      <label style={{ marginTop: "20%",marginLeft:"45%" }}>Please Select a Test suite</label>
       :""
      }
   
    </div>
  );
}

export default Report;
