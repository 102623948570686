import React from "react";
import "./AdminSidebar.css";
import "tippy.js/themes/light.css";
import logo from "./../../../assets/Icons/ignitho-logo.svg";
import dashboard from "./../../../assets/Icons/Dashboard.svg";
import project from "./../../../assets/Icons/Project.svg";
import application from "./../../../assets/Icons/Application.svg";
import stakeholder from "./../../../assets/Icons/Stakeholder.svg";
import users from "./../../../assets/Icons/Users.svg";
// import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Link } from "react-router-dom";

export default function AdminSidebar() {
  return (
    <div className="admin-sidebar">
      <div className="d-flex justify-content-center">
        <Link to="/admin/dashboard">
          <img
            src={logo}
            className="mt-2 img-fluid"
            alt="ignitho logo"        
          />
        </Link>
      </div>

      <div className="admin-side-nav close">
        <ul className="admin-nav-links mt-4">
          <li className="admin-nav-img">          
              <img src={dashboard} alt="dashboard-icon" />            
            <ul className="admin-sub-menu">
              <li>
                <Link className="link_name" to="/admin/dashboard">
                  <span style={{ paddingLeft: "40px", margin: "10px" }}>DASHBOARD</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="admin-nav-img">          
              <img src={project} alt="project" />          
            <ul className="admin-sub-menu">
              <li>
                <Link className="link_name" to="/admin/project">
                  <span style={{ paddingLeft: "40px", margin: "10px" }}>PROJECT</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="admin-nav-img">          
              <img src={application} alt="application name" />          
            <ul className="admin-sub-menu">
              <li>
                <Link className="link_name" to="/admin/application">                
                  <span style={{ paddingLeft: "40px", margin: "10px" }}>APPLICATION</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="admin-nav-img">         
          <img src={stakeholder} alt="stakeholder" />                              
            <ul className="admin-sub-menu">
              <li>
                <Link className="link_name" to="/admin/stakeholder">
                  <span style={{ paddingLeft: "40px", margin: "10px" }}>STAKEHOLDER</span>
                </Link>
              </li>
            </ul>
          </li>
     
          <li className="admin-nav-img">                      
              <img src={users} alt="users" style={{ height: "50px", width: '50px'}}/>        
            <ul className="admin-sub-menu">
              <li>
                <Link className="link_name" to="/admin/users">               
                  <span style={{ paddingLeft: "40px", margin: "10px" }}>USER MANAGEMENT</span>
                </Link>
              </li>
            </ul>
          </li>  
        </ul>
      </div>
    </div>
  );
}

