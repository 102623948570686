import "./TestReport.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Navbar from "../../Layout/Navbar/Navbar";
import chrome from "./../../../assets/Icons/Chrome.svg";
// import send from "./../../../assets/Icons/Send Mail.svg";
// import safari from "./../../../assets/Icons/Safari.svg";
// import firefox from "./../../../assets/Icons/Firefox.svg";
import { Col, Container, Row } from "react-bootstrap";
// import Mail from "../../../assets/Icons/Mail.svg";
import DownloadReport from "../../../assets/Icons/Download Report.svg";
import Back from "../../../assets/Icons/Back.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API } from "../../../global";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import Doc from '../../utils/Document';
// import PdfContainer from '../../utils/Pdf';
import { savePDF } from '@progress/kendo-react-pdf';

import { useRef } from "react";

function TestReport() {
  const [isDrawOpen, setIsDrawOpen] = useState(false);
  // const [reportList, setReportList] = useState({});
  const [passed_cases, setPassed_cases] = useState([]);
  const [faild_cases, setFaild_cases] = useState([]);
  const [skipped_cases, setSkipped_cases] = useState([]);
  const [all_cases, setAll_cases] = useState([]);
  const [run_log_status, setRunLog] = useState([]);
  const [application, setApplication] = useState({});
  const [testsuite, setTestsuite] = useState({});
  const [no_testcases_exe, setTestcasesExe] = useState(0);
  const [run_duration_testsuite, setRunDuration] = useState();
  const [all_log, setAllLog] = useState(true);
  const [all_log_data, setAllLogData] = useState({});
  const ref = useRef();
  const navigate = useNavigate()

  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    datasets: [
      {
        data: [
          Math.round(passed_cases.length / no_testcases_exe) * 100,
          Math.round(faild_cases.length / no_testcases_exe) * 100,
          Math.round((no_testcases_exe - (passed_cases.length + faild_cases.length)) /
            no_testcases_exe) *
          100,
        ],
        backgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
        hoverBackgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
        borderWidth: 0,
      },
    ],
  };
  const total_run =
    Math.round(passed_cases.length / no_testcases_exe) * 100 +
    (faild_cases.length / no_testcases_exe) * 100;
  let jjj = total_run.toString();
  console.log(typeof jjj);
  const plugins = [
    {
      id: "here comes your id for the specific plugin",
      beforeDraw: function (chart) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;

        ctx.restore();
        let fontSize = (height / 90).toFixed(2);
        ctx.font = "bold " + fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        let text = "75%",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillStyle = "#555555";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  // const plugins = [
  //   {
  //     id: "here comes your id for the specific plugin",
  //     beforeDraw: function (chart) {
  //      let width = chart.width,
  //         height = chart.height,
  //         ctx = chart.ctx;

  //       ctx.restore();
  //       let fontSize = (height / 120).toFixed(2);
  //       ctx.font = "bold " + fontSize + "em sans-serif";
  //       ctx.textBaseline = "top";
  //        let perc ="75%"
  //       let text =perc,
  //         textX = Math.round((width - ctx.measureText(text).width) / 2),
  //         textY = height / 2;
  //       ctx.fillStyle = "#555555";
  //       ctx.fillText(text, textX, textY);
  //       ctx.save();
  //     },
  //   },
  // ];
  const { id } = useParams();




  useEffect(() => {
    // eslint-disable-next-line
    // getTestsuite();
    getLogs()
    // eslint-disable-next-line
  }, []);



  const getLogs = () => {
    fetch(`${API}/tests/testsuites/report/runlog/report/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((data) => data.json())
      .then((test) => {
        console.log(test, "logs")
        console.log(test.data.passed_test_cases.length, test.data.failed_test_cases)
        setPassed_cases(test.data.passed_test_cases)
        setFaild_cases(test.data.failed_test_cases)
        setSkipped_cases(test.data.skipped_test_cases)
        setTestcasesExe(test.data.testcase_count)
        testcase_duration(test.data.test_execution_job_key)
        setApplication(test.data.application_id);
        setTestsuite(test.data.test_suite_id);
      })
  }

  //send mail func
  // const [sendMail, setSendMail] = useState(true);

  // const checkList = document.getElementById('list1');
  // checkList.getElementsByClassName('anchor')[0].onclick = function () {
  //   if (checkList.classList.contains('visible'))
  //     checkList.classList.remove('visible');
  //   else
  //     checkList.classList.add('visible');
  // }
  ///testcases/runlogs/job/
  const testcase_duration = (job) => {
    fetch(`${API}/tests/testcases/runlogs/job/${job}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        setRunDuration(ts.data.reduce((a, b) => +a + +b.run_duration, 0));
        console.log(ts.data);
        setAll_cases(ts.data);
      });
  };

  const onClick = (val) => {
    setRunLog([]);
    setIsDrawOpen((current) => !current);
    let log_parse = JSON.parse(val.run_log);
    setRunLog(log_parse[0].elements[0].steps);
    setAllLogData(log_parse[0].elements[0]);
    console.log(log_parse[0].elements[0]);
  };

  //  const createPdf = (html) => Doc.createPdf(html);

  // pdf download


  const table = React.useRef(null);
  const exportPDF = () => {
    let element = table.current;
    if (element !== null) {
      savePDF(element, {
        paperSize: 'A4',
        margin: "40"
      });
    }
  };
  return (
    <>
      <Navbar />
      <div ref={table} className="pdf-tab">


        {/* <Topgreen /> */}

        {/*Here creating TopGreen Head */}
        <Container fluid className="">
          <Row className="test-row">
            <Col md={5}>
              <div className="d-flex first-head justify-content-start">
                Test Execution Report
              </div>
            </Col>


            <Col md={7}>
              <div className="d-flex justify-content-end report-icons me-3">

                {/*Mail dropdown */}

                {/* {sendMail && <div className="send-Mail">

                <div id="list1" className="dropdown-check-list">
                  <span className="anchor">Select Stakeholders</span>
                  <ul className="items text-start overflow-scroll text-break">
                    <li><input type="checkbox" className="mr-2 ml-2" />Ram@ignitho.com </li>
                    <li><input type="checkbox" className="mr-2 ml-2" />Arun@ignitho.com</li>
                    <li><input type="checkbox" className="mr-2 ml-2" />Yasin@ignitho.com </li>
                  </ul>
                  <img src={send} className="mail-send" alt="send mail icon" />
                </div>
              </div>} */}


                {/*Mail dropdown */}

                {/* <img
                src={Mail}
                className="reportIcon"
                style={{ cursor: "pointer" }}
                alt="mail icon"
                onClick={() => setSendMail(!sendMail)}
              /> */}
                <button
                  style={{ border: "none" }}
                  onClick={exportPDF}
                  className="button"
                >
                  <img
                    src={DownloadReport}
                    className="reportIcon"
                    style={{ cursor: "pointer" }}
                    alt="dReport icon"
                  />
                </button>

                <img
                  src={Back}
                  className="reportIcon"
                  style={{ cursor: "pointer" }}
                  alt="back icon"
                  onClick={() => navigate(-1)}
                />
              </div>
            </Col>
          </Row>
        </Container>

        {/*Here starting the Execution summary part*/}

        <div className="Test-Execution-main-head row">
          {/* <PDFExport paperSize="A4" margin="1cm" ref={pdfExportComponent}> */}

          <div
            ref={ref}
            className={`Execution-sum-head overflow-hidden align-middle ${isDrawOpen && " col-8"
              }`}
            id="report-export"
          >

            <h4 className="text-start Ex-sum fs-5">Execution Summary</h4>
            <div className="TER-report-head row">
              {/* Here starting the 1 column */}


              <div className="summary-one col-md-3 overflow-hidden">

                <div className="repo-app">
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Application
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {application.name}
                  </p>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Test Suite
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {testsuite.name}
                  </p>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Environment
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {/* {reportList.environment} */}
                  </p>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Browsers
                  </h6>
                  <div className="ms-3 gap-3 d-flex">
                    <img
                      src={chrome}
                      style={{ cursor: "pointer" }}
                      alt="chrome icon"
                    />
                    {/* <img
                    src={safari}
                    style={{ cursor: "pointer" }}
                    alt="safari icon"
                  />
                  <img
                    src={firefox}
                    style={{ cursor: "pointer" }}
                    alt="firefox icon"
                  /> */}
                  </div>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Executed Time
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {" "}
                    {moment(run_duration_testsuite, "ss").format("HH:mm:ss")}
                  </p>
                </div>
              </div>

              {/* Here starting the 2 column */}
              <div className="summary-sec col-md-3">
                <div className="repo-app">
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    No of Test Case Executed
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {no_testcases_exe}
                  </p>
                  <h6 className="text-start ms-3 mt-3  text-muted">Passed</h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {Math.round(passed_cases.length / no_testcases_exe) * 100}
                  </p>
                  <h6 className="text-start ms-3 mt-3  text-muted">Failed</h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {Math.round(faild_cases.length / no_testcases_exe)* 100}
                  </p>
                  <h6 className="text-start ms-3 mt-3  text-muted">No Run</h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {/* {Math.round(skipped_cases.length)}%
                    {Math.round(no_testcases_exe)}% */}
                    {(skipped_cases.length/no_testcases_exe)*100}%
                  </p>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Test Execution Time
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">
                    {moment(run_duration_testsuite, "ss").format("HH:mm:ss")}
                  </p>
                </div>
              </div>

              {/* Here starting the 3 column */}
              <div className="summary-third col-md-3">
                <div className="repo-app">
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Automation Test Coverage
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">40%</p>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Automation Stability
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">50%</p>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Defect Density
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">10%</p>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Defect Removel Efficiency
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">40%</p>
                  <h6 className="text-start ms-3 mt-3  text-muted">
                    Defect Leakage
                  </h6>
                  <p className=" fw-bold text-muted text-start ms-3">15%</p>
                </div>
              </div>

              {/* Here starting the 4 column chart */}
              <div className="summary-chart col-md-3 align-middle justify-content-center mb-4 mt-4">
                <h4 className="text-start test-res">Test Result</h4>
                <div className="chart-res ms-5">
                  <Doughnut
                    data={data}
                    plugins={plugins}
                    className="doug-chart"
                    options={{
                      cutout: 75,
                      responsive: true,
                      maintainAspectRatio: true,
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Here starting table section*/}
            <div className={`Execution_test_report_head row`}>
              <h2 className="head_title m-0 mt-5">Test Cases Report</h2>
              <div className=" repo-table-head mt-3 ml-0">
                <table className="TER-testcase-report">
                  <thead className="repo-thead">
                    <tr>
                      <th scope="col" style={{ cursor: "pointer" }}>Test Case ID</th>
                      <th scope="col">Test Case Name</th>
                      <th scope="col">Test Duration</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody className="align-middle">
                    {all_cases.map((data) => (
                      <tr style={{ cursor: "pointer" }} onClick={() => onClick(data)}>
                        <td ><span style={{ cursor: "pointer" }}>TC{data.test_case_id.test_case_id}</span></td>
                        <td>{data.test_case_id.test_case_name}</td>
                        <td>
                          {moment(data.run_duration, "ss").format("HH:mm:ss")}
                        </td>
                        <td
                          style={{
                            color: data.run_status === "passed" ? "green" : "red",
                          }}
                        >
                          {data.run_status}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* </PDFExport> */}
          {/*Here creating the sidebar */}

          <div
            className={`sidebar-main-head ${isDrawOpen ? "col-3" : "report_sidebar-close"
              }`}
          >
            <h3 className="fs-5 TER-log">Logs</h3>
            <div className="TER-report_sidebar">
              {/* all_log_data */}
              {/* {all_log_data} */}
              {all_log ? (
                <table className="report_sideTable">
                  <tbody>
                    {run_log_status.length > 0 ? (
                      run_log_status.map((data, i) => (
                        <tr className="report_sideTr">
                          <td className="report_sideTd td-1 ml-4 align-middle text-center">{1 + i}</td>

                          <td className="report_sideTd ml-3">
                            <div>{data.name}</div>
                            <div>
                              {moment(data.result.duration, "ss").format(
                                "HH:mm:ss"
                              )}
                            </div>
                            <div
                              style={{
                                color:
                                  data.result.status === "passed"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {data.result.status}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        Unable to execute given test case, Please check the
                        gherkin language entered in the Create testcase editor
                      </span>
                    )}
                  </tbody>
                </table>
              ) : (
                <table className="report_sideTable">
                  <tbody>
                    <tr className="report_sideTr">
                      <td className="report_sideTd">
                        <div>Id:{all_log_data.id}</div>
                        <div>Keyword:{all_log_data.keyword}</div>
                        <div>Name:{all_log_data.name}</div>
                        <div>Line:{all_log_data.line}</div>
                        <div>Type:{all_log_data.type}</div>
                        <div>
                          {/* {moment(data.result.duration, 'ss').format('HH:mm:ss')} */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            {/*Here creating a div for display the view all logs */}

            <div className="TER-report-logs align-middle">
              <Link className="GeneratedLink" onClick={() => setAllLog(!all_log)}>
                {all_log ? "View All Logs" : "View Run Logs"}
              </Link>
            </div>

            {/*Ending the all run logs div */}
          </div>
        </div>
      </div>
    </>
  );
}

export default TestReport;
