import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import del from "../../../../assets/Icons/Delete.svg";
import warning from "../../../../assets/Icons/Warning.svg";
import "./DeleteStakeHolder.css";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import { API } from "../../../../global";
import { toast } from "react-toastify";

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const toggleShow = () => {
    setShow((s) => !s);
  };

  const deleteHolder = () => {
    handleClose();
    fetch(`${API}/admin/stakeholder/delete/${props.id}`, {
      method: "PUT",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then(() => {

      window.location.reload();
      toast.success("Record Deleted Successfully");
    });
  };
  return (
    <>
      <Tippy
        placement="top"
        className="tippy-tooltip"
        theme="light"
        content="Delete"
      >
        <img
          src={del}
          onClick={toggleShow}
          style={{ cursor: "pointer" }}
          alt="delete icon"
        />
      </Tippy>
      <Offcanvas
        className="del-stake-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <img src={warning} alt="warning symbol" className="stake-del-warn" />
        <h2>Are You Sure?</h2>
        <div>Do you really want to delete this record?</div>
        <div>This process cannot be undone</div>
        <div className="delete-stake-button">
          <button className="del-stake-button" onClick={() => deleteHolder()}>
            Delete
          </button>

          <button onClick={handleClose} className="cancel-delstake-button">
            Cancel
          </button>
        </div>
      </Offcanvas>
    </>
  );
}

export default function DeleteStakeHolder({ id }) {
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          id={id}
          {...props}
          placement={props.placement}
        />
      ))}
    </>
  );
}
