import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { GoPlus } from "react-icons/go";
import "tippy.js/themes/light.css";
import { API } from "../../../../global";
import "./CreateProject.css";

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [btnDisable, setBtnDisable] = useState(false)
  const toggleShow = () => setShow((s) => !s);

  const handleClose = () => {
    setProjectName("");
    setProjectDesc("");
    setFormErrors({})
    setBtnDisable(false)
    setShow(false);
  };

  const newProject = {
    created_by: localStorage.getItem("user-id"),
    updated_by: localStorage.getItem("user-id"),
    name: projectName,
    description: projectDesc,
    tenant_id: "1",
    department_id: "1",
  };
  const validate = (values) => {
    let errors = {};

    if (!newProject.name) {
      errors.name = "Name should not be blank";
    }
    if (!newProject.description) {
      errors.description = "Description should not be blank";
    } 
    return errors;
  };


  const addProject = async () => {
    validate()
    setBtnDisable(true)
 
    if(Object.keys(validate()).length === 0) {
      await fetch(`${API}/admin/project`, {
        method: "POST",
        body: JSON.stringify(newProject),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then(() => {
        handleClose();
        window.location.reload();
      });
    }else{
      setFormErrors(validate());
    }
    setBtnDisable(false)
  };

  return (
    <div>
      <div
        className="create-project-btn"
        onClick={toggleShow}
        style={{cursor : "pointer"}}
      >
        <span>
          <GoPlus className="add-icon" />
        </span>
        Create Project
      </div>

      <Offcanvas
        className="create-project-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="create-pj">
            <span>Create Project</span>
            {/* <span
              onClick={handleClose}
              style={{
                marginLeft: "200px",
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "normal",
              }}
            >
              X
            </span> */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form.Label
        className="create-pj-label"
          htmlFor="basic-url"
        >
          Project Name
        </Form.Label>
        <span>
          <Form.Control
            type="text"
            placeholder="Enter an project name"
            className="pj-story-box"
            onChange={(event) =>{
              setProjectName(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,name:"Cannot be blank"}
                return changes
               })
             }  
            } }
          
          />
          {formErrors.name && (
            <span className="error">{formErrors.name}</span>
          )}
        </span>

        <br />
        <Form.Label
        className="create-pj-label"
          htmlFor="basic-url"
        >
          Project Description
        </Form.Label>
        <span>
          <Form.Control
            type="text"
            placeholder="Enter an project description"
            className="pj-story-box"
            onChange={(event) => {
              setProjectDesc(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,description:"Cannot be blank"}
                return changes
               })
             }  
            } }
          
          />
          {formErrors.description && (
            <span className="error">{formErrors.description}</span>
          )}
        </span>

        <span className="project-button">
          <button
            type="button"
            disabled={btnDisable}
            className="create-pj-button"
            onClick={() => addProject()
              // disabled={btnDisable}
            }
          >
            Create
          </button>
          <button
            type="button"
            className="cancel-pj-button"
            onClick={() => handleClose()}
          >
            Cancel
          </button>
        </span>
      </Offcanvas>
    </div>
  );
}

export default function CreateProject() {
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample key={idx} {...props} placement={props.placement} />
      ))}
    </>
  );
}
