import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import AdminSidebar from "../../AdminSidebar/AdminSiderbar";
//import Header from "../../../Layout/Navbar/Navbar";
import AdminNavbar from "../../AdminNavbar/AdminNavbar";
import ReactTooltip from "react-tooltip";
import filter from "../../../../assets/Icons/Filter.svg";
import "./UserList.css";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
// import { API } from "../../../../global";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Oval } from "react-loader-spinner";

import Paginate from "../../../DashboardComponents/Paginate/Paginate";
import { useNavigate } from "react-router";
import CreateUsers from "../Create User/CreateUsers";
import del from "../../../../assets/Icons/Delete.svg";
import edit from "../../../../assets/Icons/Edit.svg";


function UserList() {
  const navigate = useNavigate();
  // const [userlist, setUserlist] = useState([]);
  // const [stakeEdit, setStakeEdit] = useState({});
  // const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);
  // const [expand, setExpand] = useState(false);
  const project_id = localStorage.getItem("project-id");
  const [expand, setExpand] = useState(false);
  // const [sort, setSort] = useState("asc");

  let userlist = [
    {
      "id": 1,
      "name": "Pavithiraa",
      "mail": "pavithiraa.h@ignitho.com",
      "project" : "IQA",
      "roles": "Quality Engineer",
    },
    {
      "id": 2,
      "name": "Aravind MI",
      "mail": "aravind.mi@ignitho.com",
      "project" : "IQA",
      "roles": "Admin",
    },
    {
      "id": 3,
      "name": "Arunkumar",
      "mail": "arunkumar.r@ignitho.com",
      "project" : "IQA",
      "roles": "Project Manager",
    },
    {
      "id": 4,
      "name": "Rambabu",
      "mail": "rambabu.m@ignitho.com",
      "project" : "IQA",
      "roles": "Quality Engineer",
    },
    {
      "id": 5,
      "name": "Mohammed Yasin",
      "mail": "yasin.km@ignitho.com",
      "project" : "IQA",
      "roles": "DevOps",
    },
    {
      "id": 6,
      "name": "Niranjana",
      "mail": "niranjana.j@ignitho.com",
      "project" : "IQA",
      "roles": "Project Manager",
    },
    {
      "id": 7,
      "name": "Suriya",
      "mail": "suriya.g@ignitho.com",
      "project" : "IQA",
      "roles": "Quality Engineer",
    },
  ]

  //Sort function, Note: Userlist is not dynamic so commented it

  // const sorting = (order) => {
  //   if (sort === "asc") {
  //     const sorted = [...userlist].sort((a, b) =>
  //       a[order] > b[order] ? 1 : -1
  //     );
  //     setSort("dsc");
  //     userlist(sorted);
  //   }
  //   if (sort === "dsc") {
  //     const sorted = [...userlist].sort((a, b) =>
  //       a[order] > b[order] ? 1 : -1
  //     );
  //     setSort("asc");
  //     userlist(sorted);
  //   }
  // }

  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/admin/users/pr/${project_id}`);
    }
  };

  useEffect(() => {
    get_project_id();
    // if (project_id) {
    //   getApplicationByprojectId();
    // }
    // eslint-disable-next-line
  }, [project_id]);

  // const getApplicationByprojectId = () => {
  //   setLoading(true);
  //   setStakelist([]);
  //   fetch(`${API}/admin/stakeholder/get/${localStorage.getItem("project-id")}`, {
  //     method: "GET",
  //     headers: {
  //       authorization: `${localStorage.getItem("access-key")}`,
  //     },
  //   })
  //     .then((val) => val.json())
  //     .then((app) => {
  //       console.log(app.data, "data");
  //       app.data.length > 0
  //         ? setStakelist(app.data)
  //         : setStakelist([]);
  //     })
  //     .then(() => setLoading(false));
  // };

  const editUser = (id) => {
    userlist.find((x) => x.id === id);
  };

  const deleteUser = (id) => {
    setLoading(true);
    userlist.filter((x) => x.id !== id);
    setLoading(false);
  }

  let top_green = (
    <Container fluid>
      <Row className="stackholder-test-row">
        <Col>
          <div className="d-flex first-head justify-content-start">
            User Management
          </div>
        </Col>

        <Col className="d-flex first-head justify-content-end">
          <CreateUsers />
        </Col>
      </Row>
    </Container>
  );

  let holdlist;

  if (loading) {
    holdlist = (
      <>
        <div style={{ marginTop: "15%" }}>
          <Oval
            height={80}
            width={1800}
            color="#805382"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F0E3F1#AB78AE"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </>
    );
  } else if (userlist.length === 0) {
    holdlist = (<>
      {top_green}
      <div style={{ marginTop: "19%", marginLeft: "45%" }}>
        <div style={{ marginLeft: "13%" }}> <AiOutlineInfoCircle size="30px" color="#9F9F9F" /></div>
        <h6 style={{ color: "#9F9F9F", marginLeft: "7%", marginTop: "1%" }}>Please Create a User</h6>
      </div>
    </>
    )
  } else {
    holdlist = (
      <>
        {top_green}
        <Table className="stackholder-table">
          <thead
            className="align-middle SH-thead"
            style={{
              backgroundColor: "#E6C6E840",
              color: "#805382",
            }}>
            <tr>
              <th
                className="ms-3"
                style={{ borderRight: "2px solid #805382" }}
              >
                Name{" "}
 <span onClick={() => setExpand(!expand)}>
                  {!expand ? <MdArrowDropDown size={15} /> : <MdArrowDropUp size={15} />}
                </span>
              </th>
              <th style={{ borderRight: "2px solid #805382" }}>
                Email
              </th>
              <th style={{ borderRight: "2px solid #805382" }}>
                Project
              </th>
              <th style={{ borderRight: "2px solid #805382" }}>
                Roles
              </th>
              <th className="SH-action">
                <span className="action-th" style={{ margin: "auto" }}>Actions</span>
                <img
                  src={filter}
                  className="SH-filter"
                  alt="filter icon"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {userlist.map((holder, i) => {
              return (
                <tr style={{ height: "10px" }} key={i}>
                  <td>{holder.name}</td>
                  <td>{holder.mail}</td>
                  <td>{holder.project}</td>
                  <td>{holder.roles}</td>
                  <td>
                    <div className="pro-icons">
                      <img
                        src={edit}
                        onClick={() => editUser(holder.id)}
                        style={{ cursor: "pointer" }}
                        alt="delete icon"
                      />
                      <img
                        src={del}
                        onClick={() => deleteUser(holder.id)}
                        style={{ cursor: "pointer" }}
                        alt="delete icon"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    )

  }

  return (
    <div>
      <AdminNavbar />
      <AdminSidebar />
      <ReactTooltip />
      {/*Creating table */}
      <div>
        {holdlist}
      </div>
      <Paginate />
    </div>
  );
}

export default UserList;
