import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import AdminSidebar from "../../AdminSidebar/AdminSiderbar";
//import Header from "../../../Layout/Navbar/Navbar";
import AdminNavbar from "../../AdminNavbar/AdminNavbar";
import ReactTooltip from "react-tooltip";
import filter from "../../../../assets/Icons/Filter.svg";
import "./StakeHolderList.css";
import CreateStakeHolder from "../Create Stakeholder/CreateStakeHolders";
import EditStakeholder from "../Edit Stakeholder/EditStakeholder";
import DeleteStakeHolder from "../Delete Stakeholder/DeleteStakeHolder";
import { API } from "../../../../global";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { MdArrowDropUp } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
import Paginate from "../../../DashboardComponents/Paginate/Paginate";
import { useNavigate } from "react-router";

function StakeHolderList() {
   const navigate = useNavigate();
  const [stakelist, setStakelist] = useState([]);
  const [stakeEdit, setStakeEdit] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const project_id = localStorage.getItem("project-id");
  const [sort, setSort] = useState("asc");
  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/admin/stakeholder/pr/${project_id}`);
    }
  };

  useEffect(() => {
    get_project_id();
    if (project_id) {
      getApplicationByprojectId();
    }
    // eslint-disable-next-line
  }, [project_id]);

  //Sort function

  const sorting = (order) => {
    if (sort === "asc") {
      const sorted = [...stakelist].sort((a, b) =>
        a[order] > b[order] ? 1 : -1
      );
      setSort("dsc");
      setStakelist(sorted);
    }
     if (sort === "dsc") {
      const sorted = [...stakelist].sort((a, b) =>
        a[order] > b[order] ? 1 : -1
      );
      setSort("asc");
      setStakelist(sorted);
    }
  }

  const getApplicationByprojectId = () => {
    setLoading(true);
    setStakelist([]);
    fetch(`${API}/admin/stakeholder/get/${localStorage.getItem("project-id")}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        console.log(app.data, "data");
        app.data.length > 0
          ? setStakelist(app.data)
          : setStakelist([]);
      })
      .then(() => setLoading(false));
  };



  const editStakeholder = (value) => {
    setStakeEdit(value);
  };

  let top_green = (
    <Container fluid>
    <Row className="stackholder-test-row">
      <Col>
        <div className="d-flex first-head justify-content-start">
          StakeHolders
        </div>
      </Col>

      <Col className="d-flex first-head justify-content-end">
        <CreateStakeHolder />
      </Col>
    </Row>
  </Container>
  );

  let holdlist;

  if (loading) {
    holdlist = (
      <>
      <div style={{marginTop:"15%"}}>
        <Oval
          height={80}
          width={1800}
          color="#805382"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#F0E3F1#AB78AE"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
      </>
    );
  }else if(stakelist.length === 0){
   holdlist = ( <>
      {top_green}
      <div style={{ marginTop: "19%",marginLeft: "45%" }}>
       <div style={{marginLeft: "13%" }}> <AiOutlineInfoCircle size="30px" color="#9F9F9F" /></div>
        <h6 style={{ color: "#9F9F9F" }}>Please Create a Stakeholder</h6>
      </div>
    </>
   )
  }else{
    holdlist = (
      <>
      {top_green}
          <Table className="stackholder-table">
        <thead
         className="align-middle SH-thead"
         style={{
          backgroundColor: "#E6C6E840",
          color: "#805382",
        }}>
          <tr>
            <th
              className="ms-3"
              style={{ borderRight: "2px solid #805382"}}
            >
              StakeHolder Name{" "}
                <span onClick={() => {sorting(stakelist._id) && setExpand(!expand) }}>
                  {!expand ? (
                    <MdArrowDropDown size={15} />
                  ) : (
                    <MdArrowDropUp size={15} />
                  )}
                </span>
            </th>
            <th style={{ borderRight: "2px solid #805382"}}>
              StakeHolder Mail ID
            </th>
            <th style={{ borderRight: "2px solid #805382"}}>
              Application Name
            </th>
            <th className="SH-action">
              <span className="action-th" style={{margin:"auto"}}>Actions</span>
              <img
                src={filter}
                className="SH-filter"
                alt="filter icon"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {stakelist.map((holder, i) => {
            return (
              <tr style={{ height: "10px" }} key={i}>
                <td>{holder.name}</td>
                <td>{holder.mail}</td>
                <td>{holder.application_id.name}</td>
                <td>
                  <div className="pro-icons">
                    <span onClick={() => editStakeholder(holder)}>
                      <EditStakeholder editholder={stakeEdit} />
                    </span>
                    <span onClick={() => setDeleteId(holder._id)}>
                      <DeleteStakeHolder id={deleteId} />
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      </>
    )

  }

  return (
    <div>
      <AdminNavbar/>
      <AdminSidebar />
      <ReactTooltip />
      {/*Creating table */}
      <div>
        {holdlist}
      </div>
  <Paginate/>
    </div>
  );
}

export default StakeHolderList;
