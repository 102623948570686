import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ScheduleTestsuite.css";
import Form from "react-bootstrap/Form";
import chrome from "./../../../assets/Icons/Chrome.svg";
import safari from "./../../../assets/Icons/Safari.svg";
import firefox from "./../../../assets/Icons/Firefox.svg";
import edge from "./../../../assets/Icons/Edge.svg";
import apple from "./../../../assets/Icons/iOS.svg";
import android from "./../../../assets/Icons/Android.svg";
import schedule from "../../../assets/Icons/Schedule-run.svg";
import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { API } from "../../../global";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";

const options = [
    {
        name: "Disable backdrop",
        scroll: false,
        backdrop: false,
        placement: "end",
    },
];

function OffCanvasExample({ ...props }) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setCurentId(false)
    };
    const toggleShow = () => setShow((s) => !s);

    // const [projectlist, setProjectlist] = useState([]);
    // const [projectId, setProjectId] = useState("");
    const [environment, setEnvironment] = useState("Dev Environment");
    const [version, setVersion] = useState("chrome-100");
    // const [schedule, setSchedule] = useState({});
    // const [tenant_id, setTenant_id] = useState('');
    const [frequency, setFrequency] = useState('');
    const [time, setTime] = useState('');
    const [date, setDate] = useState('')
    // const [testsuite_id, setTestsuite_id] = useState('');
    // const [testsuiteList, setTestsuiteList] = useState([]);
    const [scheduleList, setScheduleList] = useState([])
    const [currentId, setCurentId] = useState(false);
    let fff = { ...props }
    console.log(fff.scheduleId, 'popo');

    useEffect(() => {
        getTestSuite();

    }, [])

    const handleEnvironment = (event) => {
        setEnvironment(event.target.value);
    };
    const handleVersion = (event) => {
        setVersion(event.target.value);
    };
    const handleFrequency = (event) => {
        setFrequency(event.target.value);
    };
    //tests/testsuites/schedule/getid/100
    // const handleSuiteId = (event) => {
    //     setTestsuite_id(event.target.value)
    // }

    const scheduleObj = {
        schedule: {
            start_date: date,
            frequency: frequency,
            time: time
        },
        schedule_id: currentId ? fff.scheduleId : fff.scheduleId + 1,
        // tenant_id:tenant_id,
        // testsuite_id: testsuite_id,
        environment: environment,
        version: version
    }

    const postSchedule = () => {
        fetch(`${API}/tests/testsuites/schedule`, {
            method: "POST",
            body: JSON.stringify(scheduleObj),
            headers: {
                "content-type": "application/json",
                authorization: `${localStorage.getItem("access-key")}`,
            },
        }).then(() => {
            fff.getApp();
            getApplication()
            setCurentId(true)
        }).catch((err) => {
            console.log(err);
        })
    }
    const getTestSuite = () => {

        fetch(
            `${API}/tests/testsuites/`,
            {
                method: "GET",
                headers: {
                    authorization: `${localStorage.getItem("access-key")}`,
                },
            }
        )
            .then((val) => val.json())
            .then((app) => {
                console.log(app.data);
                // app.data.length > 0 ? setTestsuiteList(app.data) : setTestsuiteList([]);
            })
    }
    const getApplication = () => {
        fetch(
            `${API}/tests/testsuites/schedule/getid/${currentId ? fff.scheduleId : fff.scheduleId + 1}`,
            {
                method: "GET",
                // headers: {
                //   authorization: `${localStorage.getItem("access-key")}`,
                // },
            }
        )
            .then((val) => val.json())
            .then((app) => {
                app.data.length > 0 ? setScheduleList(app.data) : setScheduleList([]);
            })
    }

    return (
        <>
            <Tippy placement="top" theme="light" content="Schedule"  className="tippy-tooltip">
                <img
                    src={schedule}
                    onClick={toggleShow}
                    style={{ cursor: "pointer" }}
                    alt="schedule icon"
                />
            </Tippy>

            <Offcanvas
                show={show}
                onHide={handleClose}
                {...props}
                className="TS-SH-Canvas-create-head"
            >
                <Offcanvas.Body>
                    <Offcanvas.Title className="TS-SH-create mt-3">
                        Schedule Execution
                    </Offcanvas.Title>

                    <Form.Group className="mt-5" value={environment}>
                        <Form.Label className="TS-SH-edit-label">
                            Select Environment
                        </Form.Label>
                        <Form.Select
                            as="select"
                            className="select-TS-SH-env TS-SH-form"
                            value={environment}
                            onChange={handleEnvironment}
                        >
                            <option value="Alpha Testing Environment">
                                Alpha Testing Environment
                            </option>
                            <option value="Dev Environment">Dev Environment</option>
                            <option value="Cloud Environment">Cloud Environment</option>
                            <option value="Performance Testing Environment">
                                Performance Testing Environment
                            </option>
                            <option value="System Integrating Testing(SIT)">
                                System Integrating Testing(SIT)
                            </option>
                            <option value="User Acceptance Testing(UAT)">
                                User Acceptance Testing(UAT)
                            </option>
                            <option value="Security Testing">Security Testing</option>
                        </Form.Select>
                    </Form.Group>
                    <div className="TS-SH-browser-select d-flex mt-4">
                        <div className="">
                            <Form.Label htmlFor="basic-url" className="TS-SH-edit-label">
                                Select Browser / Mobile OS
                            </Form.Label>
                            <div className="TS-SH-browser-icon d-flex mb-3">
                                <img
                                    src={chrome}
                                    style={{ cursor: "pointer" }}
                                    alt="chrome icon"
                                />
                                <img
                                    src={safari}
                                    style={{ cursor: "pointer" }}
                                    alt="safari icon"
                                />
                                <img
                                    src={firefox}
                                    style={{ cursor: "pointer" }}
                                    alt="firefox icon"
                                />
                                <img src={edge} style={{ cursor: "pointer" }} alt="edge icon" />
                                <img
                                    src={apple}
                                    style={{ cursor: "pointer" }}
                                    alt="apple icon"
                                />
                                <img
                                    src={android}
                                    style={{ cursor: "pointer" }}
                                    alt="android icon"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="TS-SH-browser-version">
                        <Form.Label
                            htmlFor="basic-url"
                            className="TS-SH-browser-label"
                        // style={{ marginLeft: "15px", fontWeight: "bold" }}
                        >
                            Select Version
                        </Form.Label>

                        <Form.Group>
                            <Form.Select
                                className="d-flex mb-3 TS-SH-form"
                                value={version}
                                onChange={handleVersion}
                            >
                                <option value="Chrome 100">Chrome 100</option>
                                <option value="Chrome 99">Chrome 99</option>
                                <option value="Chrome 98">Chrome 98</option>
                                <option value="Chrome 97">Chrome 97</option>
                                <option value="Chrome 96">Chrome 96</option>
                            </Form.Select>
                        </Form.Group>
                    </div>

                    <div className="TS-SH-DP-head">
                        <Form.Label htmlFor="basic-url" className="TS-SH-datePicker">
                            Start Date
                        </Form.Label>

                        <div className="TS-SH-DP-input">
                            <input type="date" id="date" name="date"
                                onChange={(event) => setDate(event.target.value)}
                            />
                        </div>
                    </div>

                    <div className="TS-SH-Date mt-3 d-flex">

                        <div className="TS-SH-FR-head d-flex">
                            <Form.Group>
                                <Form.Label htmlFor="basic-url">Frequency</Form.Label>
                                <Form.Select
                                    className="TS-SH-period TS-SH-form d-flex"
                                    value={frequency}
                                    onChange={handleFrequency}
                                >
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                </Form.Select>
                            </Form.Group>

                            <input type="time" id="time" name="time" className="TS-SH-form TS-SH-period"
                                onChange={(event) => setTime(event.target.value)}
                            />

                            <Form.Group>
                                <Form.Select
                                    className="TS-SH-day TS-SH-period"
                                >
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="TS-SH-suite-buttons justify-content-end ml-3">
                        <button className="save-TS-SH" onClick={() => postSchedule()}>Schedule</button>
                        <button className="TS-SH-cancel-ts" onClick={handleClose}>
                            Cancel
                        </button>
                    </div>

                    {/* Schedule list */}

                    {currentId && <Table responsive="sm" className="mt-5 TS-SH-list">
                        <thead>
                            <tr>
                                <th colSpan={8}>Frequency</th>
                                <th colSpan={2}>Start Date</th>
                                <th colSpan={1}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {scheduleList.map(list => (
                                <tr >
                                    <td colSpan={8}>{list.schedule.frequency}</td>
                                    <td colSpan={2}>{list.schedule.start_date}</td>
                                    <td colSpan={1}>X</td>
                                </tr>
                            ))

                            }

                        </tbody>
                    </Table>}

                    <div className="TS-SH-suite-buttons justify-content-end ml-3">
                        <button className="save-TS-SH" onClick={handleClose}>
                            Save
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default function ScheduleTestsuite({ getSchedule, count }) {

    return (
        <>
            {options.map((props, idx) => (
                <OffCanvasExample
                    key={idx}
                    getApp={getSchedule}
                    scheduleId={count}
                    {...props}
                />
            ))}
        </>
    );
}
