import "./Topgreen.css";
import { Nav } from "react-bootstrap";
import { useEffect, useState } from "react";
import { API } from "../../../global"
import { useNavigate } from "react-router";
import { Dropdown } from "react-bootstrap";

function Topgreen() {
  const [applicationlist, setApplicationlist] = useState([]);
  const [app_selected,setApp_selected] = useState("")
  const navigate = useNavigate();
  const project_id = localStorage.getItem("project-id")
  const application_id = localStorage.getItem("app_id")
  const app_name = localStorage.getItem("app-id")
  
  useEffect(()=>{
    get_project_id()
    getApplication()
    // eslint-disable-next-line
  },[project_id])
  
  const get_project_id = () => {
    if (localStorage.getItem("app_id")) {
      navigate(`/qe/dashboard/pr/${application_id}`);
    }
  };

  const getApplication = () => {
    setApp_selected("")
    setApplicationlist([]);
    fetch(
      `${API}/admin/application/${localStorage.getItem("project-id")}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
       setApplicationlist(app.data.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      })

  };
  // const handleApplication = (event) =>{
  //   localStorage.setItem("app_id",event.target.value);
  //   const name = applicationlist.filter((val)=> val._id === event.target.value)
  //   localStorage.setItem("app-id",name[0].name)
  //   navigate(`/qe/dashboard/pr/${event.target.value}`);
  // }

  const handleSelect = (a,e)=>{
      localStorage.setItem("app_id",a);
      const name = applicationlist.filter((val)=> val._id === a)
      localStorage.setItem("app-id",name[0].name)
      setApp_selected(name[0].name)
      navigate(`/qe/dashboard/pr/${a}`);
  }
  return (
    <nav className="navbar QE-top-green-navbar navbar-light position-fixed">
      <span
        className="QE-navbar-brand"
      // style={{
      //   color: "#7eb965",
      //   font: "normal normal bold 16px/22px Montserrat",
      //   marginLeft: "40px",
      // }}
      >
        Dashboard
      </span>
      <div className="d-flex justify-content-end dashboard-drop">
        <Nav.Link>
          <label className="DHB-period position-absolute">Period</label>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="dept-admin me-2">
              This month
            </Dropdown.Toggle>

            <Dropdown.Menu className="tg-nv-drop-menu">
              <Dropdown.Item value="This month" className="dh-drop">This month</Dropdown.Item>
              <Dropdown.Item value="Last 7 days" className="dh-drop">Last 7 days</Dropdown.Item>
              <Dropdown.Item value="Last 30 days" className="dh-drop">Last 30 days</Dropdown.Item>
              <Dropdown.Item value="Last 90 days" className="dh-drop">Last 90 days</Dropdown.Item>
              <Dropdown.Item value="Lifetime" className="dh-drop">Lifetime</Dropdown.Item>
              <Dropdown.Item value="Custom" className="dh-drop">Custom</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <select
            className="form-select form-select-sm QE-s-option"
            style={{
              width: "180px",
              height: "32px",
              borderRadius: "5px",
              border: "1px solid #CCCCCC ",
            }}
          >
            <option value="This month">This month</option>
            <option value="Last 7 days">Last 7 days</option>
            <option value="Last 30 days">Last 30 days</option>
            <option value="Last 90 days">Last 90 days</option>
            <option value="Lifetime">Lifetime</option>
            <option value="Custom">Custom</option>
          </select> */}
        </Nav.Link>
        
        <Nav.Link>
          <label className="DHB-app position-absolute">Application</label>
          <Dropdown onSelect={handleSelect}>
            <div className="QE-TG-ts">
            <Dropdown.Toggle id="dropdown-basic" className="QE-s-option me-2" style={{
              background: "#7EB965",
              marginRight: "0px",
              color: "#FFFFFF",
              width: "180px",
              height: "32px",
            }}>
          {app_name ? app_name : app_selected ? app_selected : "- - Select - -"}
            </Dropdown.Toggle>
            </div>
            <Dropdown.Menu className="nv-drop-menu" >
              {applicationlist.map((app,i)=> (
                <Dropdown.Item value={app._id} 
                  className="dh-drop"
              eventKey={app._id}
                 selected={app._id === application_id ? true : false}
               key={i}>
              {app.name}
              </Dropdown.Item>
            ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* <select
            className="form-select form-select-sm QE-s-option"
            aria-label=".form-select-sm example"
            style={{
              backgroundColor: "#7EB965",
              color: "#FFFFFF",
              width: "180px",
              marginLeft: "15px",
              height: "32px",
            }}
            onChange={(e)=> {
              handleApplication(e)
              }}
          >
            <option selected>Application Name</option>
            <hr/>
            {applicationlist.map((app,i)=> (
              <option value={app._id} 
                 selected={app._id === application_id ? true : false}
               key={i}>
              {app.name}
              </option>
            ))}
          </select>
          */}
        </Nav.Link>


        <Nav.Link>
          <label className="DHB-app position-absolute ms-2">Test Suite</label>
          <Dropdown>
            <div className="QE-TG-ts">
            <Dropdown.Toggle id="dropdown-basic" className="QE-s-option me-2" style={{
              background: "#7EB965",
              marginRight: "0px",
              color: "#FFFFFF",
              width: "180px",
              height: "32px",
            }}>
            - - Select - -
            </Dropdown.Toggle>
            </div>
            <Dropdown.Menu className="nv-drop-menu">
              <Dropdown.Item value="Test Suite 1" className="dh-drop">Test Suite 1</Dropdown.Item>
              <Dropdown.Item value="Test Suite 2" className="dh-drop">Test Suite 2</Dropdown.Item>
              <Dropdown.Item value="Test Suite 3" className="dh-drop">Test Suite 3</Dropdown.Item>
              <Dropdown.Item value="Test Suite 4" className="dh-drop">Test Suite 4</Dropdown.Item>
              <Dropdown.Item value="Test Suite 5" className="dh-drop">Test Suite 5</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <select
            className="form-select form-select-sm QE-s-option"
            aria-label=".form-select-sm example"
            style={{
              backgroundColor: "#7EB965",
              marginRight: "0px",
              color: "#FFFFFF",
              width: "180px",
              height: "32px",
            }}
          >
            <option disabled>Select the Test Suite</option>
            <option value="1">Test Suite 1</option>
            <option value="2">Test Suite 2</option>
            <option value="3">Test Suite 3</option>
            <option value="3">Test Suite 4</option>
            <option value="3">Test Suite 5</option>
          </select> */}
        </Nav.Link>
      </div>
    </nav>
  );
}

export default Topgreen;
