import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import del from "./../../../assets/Icons/Delete.svg";
import warning from "./../../../assets/Icons/Warning.svg";
import "./DeleteSchedule.css";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import { toast } from "react-toastify";

import { API } from "./../../../global";
// import { useNavigate } from "react-router-dom";

const options = [
    {
        scroll: false,
        backdrop: false,
        placement: "end",
    },
];

function OffCanvasExample({ ...props }) {
    const [show, setShow] = useState(false);
    let fff = { ...props }
    let deleteScheduleData = fff.id
    let getList = fff.getSchedule
    const handleClose = () => {
        setShow(false);
    };
    const toggleShow = () => {
        setShow((s) => !s);
    };
    const deleteTestcase = () => {
        alert(deleteScheduleData)
        fetch(`${API}/tests/testsuites/schedule/${deleteScheduleData}`, {
            method: "PUT",
            headers: {
                authorization: `${localStorage.getItem("access-key")}`,
            },
        })
            .then(() => {
                handleClose();
                getList()
                toast.success("Record Deleted Successfully");
            })
           
    };
    // useEffect(() => deleteTestcase(id), [id]);

    return (
        <>
            <Tippy
                placement="top"
                className="tippy-tooltip"
                theme="light"
                content="Delete"
            >
                <img
                    src={del}
                    onClick={toggleShow}
                    style={{ cursor: "pointer" }}
                    alt="delete icon"
                />
            </Tippy>
            <Offcanvas
                className="Canvas-sh-delete-head text-center"
                show={show}
                onHide={handleClose}
                {...props}
            >
                <div className="justify-content-center sh-delete-head">
                    <img
                        src={warning}
                        alt="warning symbol"
                        className="del-sh-warn mb-3"
                    />
                    <h1>Are You Sure?</h1>
                    <div className="mt-3">Do you really want to delete this record?</div>
                    <div>This process cannot be undone</div>
                    <div className="del-sh-button d-flex justify-content-center">
                        <button className="sh-del-buton" onClick={() => deleteTestcase()}>
                            Delete
                        </button>

                        <button onClick={handleClose} className="sh-can-buton">
                            Cancel
                        </button>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
}

export default function DeleteSchedule({ deleteId,getSchedule }) {
    return (
        <>
            {options.map((props, idx) => (
                <OffCanvasExample
                    key={idx}
                    id={deleteId}
                    getSchedule={getSchedule}
                    {...props}
                    placement={props.placement}
                />
            ))}
        </>
    );
}
