import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import headerImg from "./../../../assets/Icons/headerImg.svg";
import "./AdminNavbar.css";
import profile from "./../../../assets/Icons/Profile.svg";
// import linksearch from "./../../../assets/Icons/Search.svg";
import { useMsal } from "@azure/msal-react";
import SearchIcon from "@mui/icons-material/Search";

//Using here for new model Navbar
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { API } from "../../../global";
import { useNavigate } from "react-router";
import jwt_decode from "jwt-decode";
// import { useEffect } from "react";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";

const Main_navbar = () => {
  // const dispatch = useDispatch();

  const { accounts } = useMsal();

  const name = accounts[0] && accounts[0].name;

  const navigate = useNavigate();

  const [department, setDepartment] = useState("HR");
  const [project, setProject] = useState("Ignitho Quality Accelerator");
  // const userName = getFromStorage("username");

  // const reduxUserName = useSelector(state => state?.commonLogin?.username);

  // const HandleChange = (e) => {
  //     const {name, value} = e?.target;
  //     setDepartMent(value);
  // };

  const { instance } = useMsal();

  const handleLogout = () => {
    localStorage.removeItem("azure-token");
    localStorage.removeItem("user-id");
    localStorage.removeItem("testcase-id");
    localStorage.removeItem("role");
    localStorage.removeItem("access-key");
    // localStorage.removeItem("project-id");
    localStorage.removeItem("role-name");
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRole_value(role)
  }
  const handleNavigate = (val) => {
    localStorage.setItem("role-name", role_value);
    setShow(false);
    window.location.href = `${window.location.protocol}//${window.location.host}/${val !== 'Quality Engineer' ? "qe" : ''}/dashboard`;
  };
  const handleShow = () => setShow(true);

  const roles = JSON.parse(localStorage.getItem("role"));


  const role = localStorage.getItem("role-name");
  const [role_value, setRole_value] = useState(role);
  const [pro_nav, setPro_nav] = useState("");
  const [project_selected, setProject_selected] = useState("");

  console.log(project_selected);

  const handleChange = (value) => {
    setRole_value(value);
  };

  // TODO : Temporary fix
  const [projects, setProjects] = useState([]);


  const project_id = localStorage.getItem("project-id");
  const project_name = localStorage.getItem("pro-name")
  console.log(project_id);
  console.log(project_name);

  const getProject = async () => {
    await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => setProjects(pro.data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)));
  };
  const token = localStorage.getItem("access-key");


  const runLogoutTimer = (timer) => {
    setTimeout(() => {
      toast.error("Session Expired");
      setTimeout(() => {
        localStorage.removeItem("azure-token");
        localStorage.removeItem("user-id");
        localStorage.removeItem("testcase-id");
        localStorage.removeItem("role");
        localStorage.removeItem("access-key");
        // localStorage.removeItem("project-id");
        localStorage.removeItem("role-name");
        navigate("/");
        sessionStorage.clear();
        window.location.reload();
      }, 3000);

      // handleLogout();
    }, timer);
  };

  useEffect(() => {
    getProject();
    let url = "";
    let url_split = [];
    url = window.location.href;
    url_split = url.split("/");
    setRole_value(url_split[3]);
    setPro_nav(url_split[4]);

    if (token) {
      var decoded = jwt_decode(token);
      const date_to = new Date(decoded.exp * 1000);
      const cur_date = new Date(Date.now());
      runLogoutTimer(date_to.getTime() - cur_date.getTime());
    }
    // eslint-disable-next-line
  }, [token]);


  const getApplication = (value) => {
    fetch(
      `${API}/admin/application/${value}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        // console.log("app",app.data)
        app.data.length > 0 && localStorage.setItem("application_id", app.data[0]._id);
      })

  };

  // const project_name = localStorage.getItem("project_id");
  const handleSelect = (a, e) => {
    let name = projects.filter((pro) => pro._id === a)
    setProject_selected(name[0].name)
    localStorage.removeItem('app_id')
    localStorage.removeItem('app-id')
    localStorage.setItem("project-id", a);
    localStorage.setItem("pro-name", name[0].name)
    getApplication(a);
    navigate(
      `/${role_value}/${pro_nav}/pr/${a}`
    );
  }

  console.log(handleSelect);
  
  return (
    <>
      {/* <AdminSidebar /> */}
      <Navbar bg="light" expand="sm" className="Admin-Navbar-head fixed-top">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <Container fluid className="Admin-main_nav align-middle">
          <Navbar.Brand className="Admin-navbar-brand">
            <img
              src={headerImg}
              className="Admin-head-image"
              alt="Ignitho Quality Accelerator"
            />
          </Navbar.Brand>
          <Nav>
            <div className="d-flex ms-2">
              <Form className="Admin-search-box mt-1">
                <div className="Admin-search-icon mt-4 px-1 align-middle">
                  <SearchIcon className="Admin-search-icon-size" />
                </div>
                <Form.Control
                  aria-label="Search"
                  className="Admin-head-search px-4"
                  type="search"
                  placeholder="Search"
                />
              </Form>
            </div>
            <div className="d-flex">
              <Nav.Link>
                <label className="admin-dept-lab position-absolute ms-2 mb-2">Department</label>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="dept" >
                    {department}
                  </Dropdown.Toggle>
                  <div className="nvbar_Sh">
                    <Dropdown.Menu className="ad-nv-drop-menu">
                      <Dropdown.Item value="HR" onClick={(e) => setDepartment(e.target.text)}>HR</Dropdown.Item>
                      <Dropdown.Item value="Product" onClick={(e) => setDepartment(e.target.text)}>Product</Dropdown.Item>
                      <Dropdown.Item value="QA" onClick={(e) => setDepartment(e.target.text)}>QA</Dropdown.Item>
                      <Dropdown.Item value="Testing" onClick={(e) => setDepartment(e.target.text)}>Testing</Dropdown.Item>
                    </Dropdown.Menu>
                  </div>
                </Dropdown>
              </Nav.Link>


              <Nav.Link>
                <label className="proj-lab position-absolute ms-2 mb-1">Project</label>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="dept" >
                    {/* {project_selected ? project_selected : project_name ? project_name : "- - Select - -"} */}
                    {project}
                  </Dropdown.Toggle>
                  <div className="nvbar_Sh">
                    <Dropdown.Menu className="qe-nv-drop-menu" id="project"
                    >
                      {/* TODO: Temporary fix */}
                      <Dropdown.Item value="Ignitho Quality Accelerator" onClick={(e) => setProject(e.target.text)}>Ignitho Quality Accelerator</Dropdown.Item>
                      <Dropdown.Item value="Ignitho Hiring Accelerator " onClick={(e) => setProject(e.target.text)}>Ignitho Hiring Accelerator </Dropdown.Item>
                      <Dropdown.Item value="Ignitho Data Accelerator" onClick={(e) => setProject(e.target.text)}>Ignitho Data Accelerator</Dropdown.Item>
                      {/* {projects.map((res, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        className="val"
                        selected={res._id === project_id ? true : false}
                        style={{ color: "black" }}
                        eventKey={res._id}
                        value={res._id}
                      >
                        {res.name}
                      </Dropdown.Item>
                    );
                  })}  */}
                    </Dropdown.Menu>
                  </div>
                </Dropdown>

              </Nav.Link>
            </div>
          </Nav>
          {/*          
          <div className="profile" >
            <div className="p-name">
              <div className="user-text"></div>
              <div className="role-text">(Role: {role[0]})</div>
            </div>
            <div className="profile-pic ml-5">
              <img src={profile} alt="profile icon" />
            </div>
          </div>  */}
          <div className="dropdown">
            <div
              className="dropdown-toggle d-flex gap-2"
              style={{ cursor: "pointer" }}
              data-bs-toggle="dropdown"
            >
              <span>
                {name}
                <div>(Role: {role})</div>
              </span>
              <div>
                <img src={profile} alt="profile icon" />
              </div>
            </div>
            <div className="nvbar_Sh">
              <div className="dropdown-menu">
                {roles.length > 1 ? (
                  <div
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={handleShow}
                  >
                    Choose Role
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLogout()}
                >
                  LogOut
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose a role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {roles.map((val, i) => {
              return (
                <div>
                  {" "}
                  <input
                    type="radio"
                    checked={role_value === val ? true : false}
                    onChange={() => handleChange(val)}
                    value={role_value}
                    key={i}
                  />{" "}
                  {/* TODO: remove this hack and add a display name entry in role table > */}
                  {val === 'Quality Engineer' ? "Quality Engineer" : null}
                  {val === 'Admin' ? "Admin" : null}
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="" onClick={() => handleNavigate()}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Main_navbar;
