import React, { useEffect, useState } from "react";
import Header from "../../Layout/Navbar/Navbar";
import "./CloneTestcase.css";
// import { GoPlus } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { SelectionText } from "@uiw/react-textarea-code-editor";
//import Editor from "@monaco-editor/react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-gherkin";
import "prismjs/themes/prism.css";
import { API } from "../../../global";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import execute from './../../../assets/Icons/run-icon.svg';

export default function CloneTestcase() {
  const { id } = useParams();

  const [testcase, setTestcase] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${API}/tests/testcases/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((data) => data.json()) //Response Object
      .then((tcs) => {
        console.log("testcase", tcs.data);
        setTestcase(tcs.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [id]);

  let clone_test;

  if (loading) {
    clone_test = (
      <div>
        <div>
          <Oval
            height={80}
            width={1800}
            color="#805382"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F0E3F1#AB78AE"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {testcase ? <Create testcase={testcase} /> : <h2>{clone_test}</h2>}
    </div>
  );
}

function Create({ testcase }) {
  const navigate = useNavigate();
  const textRef = React.useRef();

  useEffect(() => {
    if (textRef.current) {
      const obj = new SelectionText(textRef.current);
      console.log("obj:", obj);
    }

    getProjects();
    getApplication(testcase.project_id)
    // eslint-disable-next-line
  }, []);

  const [userstory, setUserstory] = useState(testcase.user_story);
  const [name, setName] = useState(testcase.test_case_name);
  const [type, setType] = useState(testcase.test_case_type);
  const [projectlist, setProjectlist] = useState([]);
  const [applicationlist, setApplicationlist] = useState([]);
  const [projectId, setProjectId] = useState(testcase.project_id);
  const [applicationId, setApplicationId] = useState(testcase.application_id);

  const testcaseId = localStorage.getItem("testcase-id");
  const [description, setDescription] = useState(
    testcase.test_case_description
  );
  const [eresult, setEresult] = useState(testcase.expected_result);
  const [code, setCode] = React.useState(testcase.test_steps);
  const [formErrors, setFormErrors] = useState({});
  // const app_selected = applist.name;
  // const [app_selected, setApp_selected] = useState(applist.name);
  // const appid = applist._id;
  // const [appid, setAppid] = useState(applist._id);

  // const testcasesByAppId = (id) => {
  //   setAppid(id);
  // };

  const handleTestcaseType = (event) => {
    setType(event.target.value);
    // console.log(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {test_case_type, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,test_case_type:"Cannot be blank"}
        return changes
       })
     }  
  };
  const handleProjectId = (event) => {
    setProjectId(event.target.value);
    getApplication(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,project_id:"Cannot be blank"}
        return changes
       })
     } 
  };
  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,application_id:"Cannot be blank"}
        return changes
       })
     } 
  };



  const getProjects = async () => {
    await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      //.then((data) => console.log(data.data))
      .then((pro) => {
        setProjectlist(pro.data);
        // pro.data.length > 0 ? setProjectId(pro.data[0]._id) : setProjectId();
      })
    // .then(() => getApplication());
    //setLoading(false);
  };
  const getApplication = async (val) => {
    setApplicationlist([]);
    await fetch(`${API}/admin/application/${val}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        app.data.length > 0
          ? setApplicationlist(app.data)
          : setApplicationlist([]);
        // app.data.length > 0
        //   ? setApplicationId(app.data[0]._id)
        //   : setApplicationId("");
      });
  };
  const UpdatedTestcase = {
    created_by: localStorage.getItem("user-id"),
    updated_by: localStorage.getItem("user-id"),
    test_case_name: name,
    test_case_type: type,
    user_story: userstory,
    test_case_description: description,
    last_run_status: "Pending",
    expected_result: eresult,
    test_steps: code,
    project_id: projectId,
    application_id: applicationId,
    test_case_id: +testcaseId + 1,
  };

  const validate = () => {
    let errors = {};
    if (!UpdatedTestcase.user_story) {
      errors.user_story = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_case_type) {
      errors.test_case_type = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_case_name) {
      errors.test_case_name = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_case_description) {
      errors.test_case_description = "Cannot be blank";
    } 
    if (!UpdatedTestcase.expected_result) {
      errors.expected_result = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_steps) {
      errors.test_steps = "Cannot be blank";
    } 
    if (!UpdatedTestcase.project_id) {
      errors.project_id = "Cannot be blank";
    } 
    if (!UpdatedTestcase.application_id) {
      errors.application_id = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_case_id) {
      errors.test_case_id = "Cannot be blank";
    } 
    return errors;
  };

  const cloneTestcase = (a) => {
    validate()
    if(Object.keys(validate()).length === 0) {
    let publish_testcase =
      a === "0"
        ? { ...UpdatedTestcase }
        : { ...UpdatedTestcase, published: true };
    if (
      UpdatedTestcase.user_story === "" ||
      UpdatedTestcase.test_case_type === "" ||
      UpdatedTestcase.test_case_name === "" ||
      UpdatedTestcase.test_case_description === "" ||
      UpdatedTestcase.last_run_status === "" ||
      UpdatedTestcase.expected_result === "" ||
      UpdatedTestcase.test_steps === ""
    ) {
      alert("Please fill all the required fields");
    } else {
      fetch(`${API}/tests/testcases`, {
        method: "POST",
        body: JSON.stringify(publish_testcase),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then(() => {
        navigate("/qe/testcase");
        a === "0"
          ? toast.success("Saved as Draft Successfully")
          : toast.success("Published Successfully");
        createRevision(a);
      });

    }
  }else{
    setFormErrors(validate());
  }
  };
  function handleEditorChange(value) {
    setCode(value);
    if(value.length !== 0) {
      setFormErrors(current => {
    const {test_steps, ...rest} = current;
    return rest
       });
     }else if(value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,test_steps:"Cannot be blank"}
        return changes
       })
     } 
  }
  const createRevision = (a) => {
    if (a === "1") {
      fetch(`${API}/tests/testcases/revision`, {
        method: "POST",
        body: JSON.stringify({
          test_case_id: UpdatedTestcase.test_case_id,
          test_steps: UpdatedTestcase.test_steps,
          updated_by: UpdatedTestcase.updated_by,
        }),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      });
    }
  };

  return (
    <>
      <div className="testcase-create">
        <Header />

        <div>
          <Container fluid>
            <Row className="clonetest_test-row">
              <Col sm={5}>
                <div className="d-flex cln-first-head justify-content-start">
                  Test Cases
                </div>
              </Col>


            </Row>
          </Container>
        </div>
      </div>
      {/*
      Adding second header
     */}
      <div>
        <Container fluid className="clone_test_Cfluid">
          <Row>
            <Col sm={4}>
              <div className="TC-cln-sec-head d-flex justify-content-start">
                Clone Test Case
              </div>
            </Col>
            <Col>
            <div className="d-flex justify-content-end me-3"><button className="clt-run-button">
              <img
                src={execute}
                style={{ color: "white", backgroundColor: "none",width:"19px" }}
                alt="run icon"
                className="me-2"
              />
           
            Run</button>
            </div>
            
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid >
        <Row className="mt-4 overflow-hidden">
          <Col sm={4}>
            <div className="justify-content-center ms-5 text-start">
              <Form className="TC-cln-user-story-menus">
                <Form.Group>
                  <Form.Label className="mb-1">Select Project</Form.Label>
                  <span>
                  <Form.Select
                    type="text"
                    placeholder="Select Project"
                    className="clone-form mb-3"
                    required
                    value={projectId}
                    onChange={handleProjectId}
                  >
                    <option>select Project</option>
                    {projectlist.map((data, i) => (
                      <option
                        // selected={data._id === projectId ? true : false}
                        value={data._id}
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                  {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}
        </span>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="mb-1">Select Application</Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="Select Application"
                    className="clone-form mb-3"
                    required
                    value={applicationId}
                    onChange={handleApplicationId}
                  >
                    <option>select Application</option>
                    {applicationlist.map((data, i) => (
                      <option
                        key={i}
                        // selected={data._id === applicationId ? true : false}
                        value={data._id}
                      >
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                  {formErrors.application_id && (
            <span className="error">{formErrors.application_id}</span>
          )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="mb-1">User Story</Form.Label>
                  <Form.Control
                    type="text"
                    className="clone-form mb-3"
                    value={userstory}
                    onChange={(event) =>{
                      setUserstory(event.target.value)
                      if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {user_story, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,user_story:"Cannot be blank"}
                return changes
               })
             }   
                    } }
                  />
                </Form.Group>
                {formErrors.user_story && (
            <span className="error">{formErrors.user_story}</span>
          )}
                <Form.Group>
                  <Form.Label className="mb-1">Test Case Type</Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="Select Test Case Type"
                    className="clone-form mb-3"
                    required
                    value={type}
                    onChange={handleTestcaseType}
                  >
                    <option value="Functional">Functional</option>
                    <option value="System">System</option>
                    <option value="Performance">Performance</option>
                    <option value="UI">UI</option>
                    <option value="Backend">Backend</option>
                  </Form.Select>
                  {formErrors.test_case_type && (
            <span className="error">{formErrors.test_case_type}</span>
          )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="mb-1">Test Case Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    className="clone-form mb-3"
                    value={name}
                    onChange={(event) =>{
                        setName(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {test_case_name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,test_case_name:"Cannot be blank"}
                return changes
               })
             }   
                      }}
                  />
                   {formErrors.test_case_name && (
            <span className="error">{formErrors.test_case_name}</span>
          )}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-1">
                    Test Case Description
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    required
                    className="clone-form mb-3"
                    value={description}
                    style={{
                      border: "1px #9F9F9F solid",
                      borderRadius: "5px",
                      height: "30px",
                    }}
                    onChange={(event) => {
                      setDescription(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {test_case_description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,test_case_description:"Cannot be blank"}
                return changes
               })
             } 
                    }}
                  />
                   {formErrors.test_case_description && (
            <span className="error">{formErrors.test_case_description}</span>
          )}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-1">Expected Result</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    className="clone-form mb-3"
                    value={eresult}
                    style={{
                      border: "1px #9F9F9F solid",
                      borderRadius: "5px"
                    }}
                    onChange={(event) =>{
                      setEresult(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {expected_result, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,expected_result:"Cannot be blank"}
                return changes
               })
             } 
                    }}
                  />
                   {formErrors.expected_result && (
            <span className="error">{formErrors.expected_result}</span>
          )}
                </Form.Group>
              </Form>
            </div>
          </Col>

          <Col sm={8} >
            <Col className="text-start clone-editor-head mb-1">Editor</Col>
            <div className="edit-clone">
              <div className="editor-code d-flex justify-content-end">
                {/* <Editor          
                className="cln-react-editors p-2 me-3"
                value={code}
                required
                defaultLanguage="gherkin"
                defaultValue={code}
                onChange={handleEditorChange}
                variant="transparent"
              /> */}
                <Editor
                  width="calc(105% - 70px)"
                  className="cln-react-editors me-3"
                  value={code}
                  onValueChange={handleEditorChange}
                  highlight={code =>highlight(code, languages.gherkin).split('\n')
                    .map(
                      (line,index) =>`<span><span style='margin-right:10px; color:grey'>${index+1}</span><span>${line}</span></span>`
                    )
                    .join('\n')}
                    style={{
                      overflow: "auto",
                      maxHeight: 500
                    }}
                  padding={10}
                  // padding={20}
                  placeholder={"WRITE YOUR TESECASE HERE"}
                />
                {formErrors.test_steps && (
            <span className="error">{formErrors.test_steps}</span>
          )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="mt-4">
        <Row>
          <Col>
            <div className="Editor-bottom-btns d-flex justify-content-end me-3 mb-3">
              <button
                // variant="normal"
                // size="sm"
                className="me-3 ct-edit-save-as"
                onClick={() => cloneTestcase("0")}
              >
                Save As Draft
              </button>
              <button
                // variant="normal"
                // size="sm"
                type="submit"
                className="me-3 ct-edit-publish"
                onClick={() => {
                  cloneTestcase("1");
                }}
              >
                Publish
              </button>
              <button
                // variant="normal"
                // size="sm"
                type="cancel"
                className="ct-edit-cancel"
                onClick={() => navigate("/qe/testcase")}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
