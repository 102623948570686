import React, { useEffect, useState } from 'react';
import Navbar from '.././Layout/Navbar/Navbar';
import { Col, Container, Row, Table } from "react-bootstrap";
//import { Oval } from "react-loader-spinner";
//import { useNavigate } from "react-router-dom";
// import { GoPlus } from "react-icons/go";
import "./Schedule.css";
import Tippy from "@tippyjs/react";
import filter from "./../../assets/Icons/Filter.svg";
import execute from "./../../assets/Icons/ExecuteNow.svg";
//import edit from "./../../assets/Icons/Edit.svg";
import publish from "./../../assets/Icons/published.svg";
//import unpublish from "./../../assets/Icons/unpublished.svg";
import EditSchedule from './EditSchedule/EditSchedule';
import CreateSchedule from "./CreateSchedule/CreateSchedule"
import { API } from '../../global';
// import Paginate from '../DashboardComponents/Paginate/Paginate';
import DeleteSchedule from './DeleteSchedule/DeleteSchedule';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

function Schedule() {

  const [scheduleList, setSchedule] = useState([]);
  const [schedule_id, setScheduleId] = useState('');
  const [expand, setExpand] = useState(false);
  const [scheduleStake, setScheduleStake] = useState([]);

  useEffect(() => {
    getApplication();
    getStakeholder();
  }, [])

  //  const navigate = useNavigate();
  const getApplication = () => {
    fetch(
      `${API}/tests/testsuites/schedule`,
      {
        method: "GET",
        // headers: {
        //   authorization: `${localStorage.getItem("access-key")}`,
        // },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        console.log(app.data);
        setScheduleId(app.data[app.data.length - 1].schedule_id);
        app.data.length > 0 ? setSchedule(app.data) : setSchedule([]);
      })
  }

  const getStakeholder = () => {
    // setLoading(true)
    fetch(`${API}/admin/stakeholder`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        // setScheduleStake(pro.data)

        setScheduleStake(pro.data);
        // setSelectedValue(stakes);
        console.log(pro.data, "stakes schedule");
      })
    // .then(() => setLoading(false))
  };

  return (
    <>
      <div className="">
        <Navbar />

        {/*Topgreen */}

        <Container fluid className="position-fixed SH-head">
          <Row className="SH-row">
            <Col sm={5}>
              <div className="d-flex SH-first-head justify-content-start">
                Schedule List
              </div>
            </Col>

            <Col sm={7}>
              <div className="d-flex justify-content-end SH-test-btns">
                <div className="btn-group">
                  <label className="SH-bulk position-absolute">Action</label>
                  <button
                    type="button"
                    className="SH-bulk-btn me-3 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    size="sm"
                    aria-expanded="false"
                  >
                    Bulk Action
                  </button>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item" style={{ fontSize: "14px" }}>
                      Execute Now
                    </li>
                  </ul>
                </div>

                <div className="btn-group">
                  <label className="SH-App position-absolute">Application</label>
                  <button
                    type="button"
                    className="SH-bulk-btn me-3 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    size="sm"
                    aria-expanded="false"
                  >- - Select - -
                  </button>
                  <ul className="dropdown-menu dScroll">
                    <li
                      className="dropdown-item">
                      All
                    </li>

                  </ul>
                </div>

                <div>
                  <CreateSchedule getSchedule={getApplication} count={schedule_id} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Table */}

        <div className='SH-table-main'>

          <Table className="SH-table">
            <thead
              className="align-middle TC-thead"
              style={{
                backgroundColor: "#E6C6E840",
                color: "#805382",
              }}
            >
              <tr className="align-middle">
                <th style={{ borderRight: "2px solid #805382", width: "10%" }}>
                  <input type="checkbox" style={{ margin: "6px", zoom: "1" }} />
                  <span onClick={() => setExpand(!expand)}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                  S.No
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Test Suite Name
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Environment
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Schedule Frequency
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "10%" }}>
                  Status
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "12%" }}>
                  Created By
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "12%" }}>
                  Updated By
                </th>
                <th style={{ width: "15%" }}>
                  <span style={{ marginLeft: "30px", justifyContent: "center", alignContent: "center" }} className="SH-filter-icon">Actions</span>
                  <img
                    src={filter}
                    className="filter"
                    alt="filter icon"
                  />
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {scheduleList.map((list, i) => (
                <tr style={{ height: "10px" }} className="mt-5">
                  <td>
                    <input type="checkbox" style={{ margin: "5px", zoom: "1" }} />{" "}
                    {i + 1}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"

                    >
                      <img
                        src={publish}
                        style={{ marginRight: "10px" }}
                        alt="publish icon"
                      />
                    </Tippy>

                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={list.testsuite_id.name}
                    >
                      <span data-tip="hello world">
                        {list.testsuite_id.name}
                      </span>
                    </Tippy>
                  </td>
                  <td>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={list.environment}
                    >
                      <span data-tip="hello world">
                        {list.environment}
                      </span>
                    </Tippy>
                  </td>
                  <td>

                    <span data-tip="hello world">{list.frequency}</span>

                  </td>
                  <td>-----
                  </td>

                  <td>
                    {list.created_by.Ad_username}
                  </td>
                  <td>
                    {list.updated_by.Ad_username}
                  </td>
                  <td>
                    <div className="SH-icons">
                      <Tippy
                        placement="top"
                        className="tippy-tooltip"
                        theme="light"
                        content="Execute"
                      >
                        <img
                          src={execute}
                          className="action"
                          style={{ cursor: "pointer" }}
                          alt="execute icon"
                        />
                      </Tippy>

                      <EditSchedule editData={list} getSchedule={getApplication} stakeData={scheduleStake} />
                      <DeleteSchedule deleteId={list._id} getSchedule={getApplication} />
                    </div>
                  </td>
                </tr>
              ))
              }

            </tbody>
          </Table>

        </div>
        {/* <Paginate /> */}
      </div>
    </>
  )
}

export default Schedule