import React, { useEffect } from "react";
import filter from "../../../../assets/Icons/Filter.svg";
import Table from "react-bootstrap/Table";
import "./ApplicationList.css";
import CreateApplication from "../CreateApplication/CreateApplication";
import DeleteTcButton from "../DeleteApplication/DeleteApplication";
import EditApplication from "../EditApplication/EditApplication";
import { API } from "./../../../../global";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminSidebar from "../../AdminSidebar/AdminSiderbar";
// import Header from "../../../Layout/Navbar/Navbar";
import { Oval } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import { useNavigate } from "react-router";
import AdminNavbar from "../../AdminNavbar/AdminNavbar";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import { MdArrowDropUp } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
// import Paginate from "../../../DashboardComponents/Paginate/Paginate";

function ApplicationList() {
  const [applicationlist, setApplicationlist] = useState([]);
  const [applicationEdit, setApplicationEdit] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(true);
  const [expand, setExpand] = useState(false);
  const [sort, setSort] = useState("asc");
  const navigate = useNavigate();

  // const token = localStorage.getItem("access-key");
  const project_id = localStorage.getItem("project-id");
  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/admin/application/pr/${project_id}`);
    }
  };

  //sort function
  
  const sorting = (order) => {
    if (sort === "asc") {
      const sorted = [...applicationlist].sort((a, b) =>
        a[order] > b[order] ? 1 : -1
      );
      setApplicationlist(sorted);
      setSort('dsc');
    }
    if (sort === "dsc") {
      const sorted = [...applicationlist].sort((a, b) =>
        a[order] > b[order] ? 1 : -1
      );
      setApplicationlist(sorted);
      setSort('asc');
    }
  }

  useEffect(() => {
    get_project_id();
    if (project_id) {
      getApplicationByprojectId();
    }

    // eslint-disable-next-line
  }, [project_id]);

  // const getApplication = async () => {

  //     const appList = await fetch(`${API}/admin/application`, {
  //       method: "GET",
  //       headers: {
  //         authorization: `${localStorage.getItem("access-key")}`,
  //       },
  //     });
  //     const list = await appList.json();
  //     setApplicationlist(list.data);
  //     setLoading(false);

  // };

  const getApplicationByprojectId = () => {
    setLoading(true);
    setApplicationlist([]);
    fetch(`${API}/admin/application/${localStorage.getItem("project-id")}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        console.log(app.data, "data");
        app.data.length > 0
          ? setApplicationlist(app.data)
          : setApplicationlist([]);
      })
      .then(() => setLoading(false));
  };

  const editApplication = async (value) => {
    setApplicationEdit(value);
  };

  const top_green = (
    <Container fluid>
      <Row className="app-test-row">
        <Col>
          <div className="d-flex first-head justify-content-start">
            Applications
          </div>
        </Col>

        <Col className="d-flex first-head justify-content-end">
          <CreateApplication />
        </Col>
      </Row>
    </Container>
  );

  let applist;
  if (!project_id) {
    applist = (
      <>
        {/* {top_green} */}
        <div style={{ marginTop: "19%", marginLeft: "45%" }}>
          <div style={{ marginLeft: "10%" }}>
            {" "}
            <AiOutlineInfoCircle size="30px" color="#9F9F9F" />
          </div>
          <h6 style={{ color: "#9F9F9F" }}>Please Select a Project</h6>
        </div>
      </>
    );
  } else if (loading) {
    applist = (
      <>
        <div style={{ marginTop: "15%" }}>
          <Oval
            height={80}
            width={1800}
            color="#805382"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F0E3F1#AB78AE"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </>
    );
  } else if (applicationlist.length === 0 && project_id !== null) {
    applist = (
      <>
        {top_green}
        <div style={{ marginTop: "19%", marginLeft: "44%" }}>
          <div style={{ marginLeft: "13%" }}>
            {" "}
            <AiOutlineInfoCircle size="30px" color="#9F9F9F" />
          </div>
          <h6 style={{ color: "#9F9F9F" }}>Please Create an Application</h6>
        </div>
      </>
    );
  }
  else if (applicationlist.length === 0 && project_id === null) {
    applist = (
      <>
        {top_green}
        <div style={{ marginTop: "19%", marginLeft: "44%" }}>
          <div style={{ marginLeft: "13%" }}>
            {" "}
            <AiOutlineInfoCircle size="30px" color="#9F9F9F" />
          </div>
          <h6 style={{ color: "#9F9F9F" }}>Please Select a Project</h6>
        </div>
      </>
    );
  } else {
    applist = (
      <>
        {top_green}
        <Table className="app-list-table">
          <thead
          className="align-middle AL-thead"
            style={{
              backgroundColor: "#E6C6E840",
              color: "#805382",
            }}
          >
            <tr>
              <th style={{ borderRight: "2px solid #805382", width: "16%" }}>
                Application ID{" "}
                <span onClick={() => { sorting(applicationlist._id) || setExpand(!expand) }}>
                  {!expand ? (
                    <MdArrowDropDown size={15} />
                  ) : (
                    <MdArrowDropUp size={15} />
                  )}
                </span>
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "16%" }}>
                Application Name{" "}
                <span onClick={() => { sorting(applicationlist._id) || setExpand(!expand) }}>
                  {!expand ? (
                    <MdArrowDropDown size={15} />
                  ) : (
                    <MdArrowDropUp size={15} />
                  )}
                </span>
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "16%" }}>
                Application Description
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "16%" }}>
                Application type
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "16%" }}>
                Project
              </th>
              <th style={{ width: "16%" }}>
                <span style={{ marginLeft: "30px", width: "16%" }}>
                  Actions
                </span>
                <img
                  src={filter}
                  style={{ marginLeft: "50px" }}
                  className="filter"
                  alt="filter icon"
                />
              </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {applicationlist.map((data, i) => {
              return (
                <tr key={i} style={{ height: "10px" }}>
                  <td>{"AID" + i + Number(1)}</td>
                  <td>{data.name}</td>
                  <td> <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={data.description}
                    >
                      <span data-tip="hello world">{data.description}</span>
                    </Tippy>
                   </td>
                  <td>{data.type}</td>
                  <td>{data.project_id.name}</td>
                  <td>
                    <div className="app-icons">
                      <span onClick={() => editApplication(data)}>
                        <EditApplication editApp={applicationEdit} />
                      </span>
                      <span onClick={() => setDeleteId(data._id)}>
                        <DeleteTcButton delId={deleteId} />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody> 
          
        </Table>
       
      </>
    );
  }

  return (
    <>
      <AdminNavbar />
      <AdminSidebar />
      <div className="app-list">
        <ReactTooltip />
        <div>{applist}</div>
        {/* <Paginate/> */}
      </div>
    </>
  );
}

export default ApplicationList;
