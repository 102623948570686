import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import del from "./../../../assets/Icons/Delete.svg";
import warning from "./../../../assets/Icons/Warning.svg";
import "./DeleteTestcase.css";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import { toast } from "react-toastify";

import { API } from "./../../../global";
// import { useNavigate } from "react-router-dom";

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const toggleShow = () => {
    setShow((s) => !s);
  };
  const id = props.id;
  const deleteTestcase = (id) => {
    handleClose();
    fetch(`${API}/tests/testcases/delete/${id}`, {
      method: "PUT",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then(() => {
        
        toast.success("Record Deleted Successfully");
      })
      .then(() => window.location.reload());
  };
  // useEffect(() => deleteTestcase(id), [id]);

  return (
    <>
      <Tippy
        placement="top"
        className="tippy-tooltip"
        theme="light"
        content="Delete"
      >
        <img
          src={del}
          onClick={toggleShow}
          style={{ cursor: "pointer" }}
          alt="delete icon"
        />
      </Tippy>
      <Offcanvas
        className="Canvas-delete-head text-center"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <div className="justify-content-center delete-head">
          <img
            src={warning}
            alt="warning symbol"
            className="del-tc-warn mb-3"
          />
          <h1>Are You Sure?</h1>
          <div className="mt-3">Do you really want to delete this record?</div>
          <div>This process cannot be undone</div>
          <div className="del-tc-button d-flex justify-content-center">
            <button className="del-buton" onClick={() => deleteTestcase(id)}>
              Delete
            </button>

            <button onClick={handleClose} className="can-buton">
              Cancel
            </button>
          </div>
        </div>
      </Offcanvas>
    </>
  );
}

export default function DeleteTcButton({ id }) {
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          id={id}
          {...props}
          placement={props.placement}
        />
      ))}
    </>
  );
}
