import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./Summary.css";
import { useEffect, useState } from "react";
import { API } from "../../../global";
import { useParams } from "react-router";
// import moment from "moment";

let slidesToShow = 5;

const PreviousBtn = (props) => {
  // console.log(props);
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <span style={{ color: "#805382", fontSize: "30px" }}>
            <i className="bi bi-chevron-left"></i>
          </span>
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  // console.log(props);
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <span style={{ color: "#805382", fontSize: "30px" }} className="nextArr">
            <i className="bi bi-chevron-right"></i>
          </span>
        </div>
      )}
    </>
  );
};
ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  datasets: [
    {
      data: [12, 6, 2],
      backgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      hoverBackgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      borderWidth: 0,
    },
  ],
};

const data1 = {
  datasets: [
    {
      data: [25, 14, 5],
      backgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      hoverBackgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      borderWidth: 0,
    },
  ],
};

const data2 = {
  datasets: [
    {
      data: [44, 12, 4],
      backgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      hoverBackgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      borderWidth: 0,
    },
  ],
};

const data3 = {
  datasets: [
    {
      data: [30, 3, 2],
      backgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      hoverBackgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      borderWidth: 0,
    },
  ],
};

const data4 = {
  datasets: [
    {
      data: [44, 6, 2],
      backgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      hoverBackgroundColor: ["#7EB965", "#DC3545", "#CECECE"],
      borderWidth: 0,
    },
  ],
};

const plugins = [
  {
    id: "here comes your id for the specific plugin",
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      var fontSize = (height / 120).toFixed(2);
      ctx.font = "bold " + fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = "60%",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = "#555555";
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  },
];

//  TODO : Temporary fix
const plugins1 = [
  {
    id: "here comes your id for the specific plugin",
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      var fontSize = (height / 120).toFixed(2);
      ctx.font = "bold " + fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = "56%",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = "#555555";
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  },
];

const plugins2 = [
  {
    id: "here comes your id for the specific plugin",
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      var fontSize = (height / 120).toFixed(2);
      ctx.font = "bold " + fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = "73%",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = "#555555";
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  },
];

const plugins3 = [
  {
    id: "here comes your id for the specific plugin",
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      var fontSize = (height / 120).toFixed(2);
      ctx.font = "bold " + fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = "85%",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = "#555555";
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  },
];

const plugins4 = [
  {
    id: "here comes your id for the specific plugin",
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      var fontSize = (height / 120).toFixed(2);
      ctx.font = "bold " + fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = "84%",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = "#555555";
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  },
];

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

function Summary() {
  const [width, setWidth] = useState(window.innerWidth);
  const [testsuiteData, setTestsuiteData] = useState({});
  // const [testcaseData, setTestcaseData] = useState({});
  const { context } = useParams();
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  const project_id = localStorage.getItem("project-id");
  const application_id = localStorage.getItem("app_id");

  useEffect(() => {
    getTestsuiteLogs();
    getTestcaseLogs();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
    // eslint-disable-next-line
  }, [context]);


  const getTestsuiteLogs = () => {
    fetch(project_id && application_id === null ? `${API}/tests/testsuite/testlog/?project_id=${project_id}` : `${API}/tests/testsuite/testlog/?project_id=${project_id}&&application_id=${application_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((data) => data.json())
      .then((val) => {
        setTestsuiteData(val)
      })
  }
  const getTestcaseLogs = () => {
    fetch(project_id && application_id === null ? `${API}/tests/testsuite/summary/?project_id=${project_id}` : `${API}/tests/testsuite/summary/?project_id=${project_id}&&application_id=${application_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((data) => data.json())
      .then((val) => {
        console.log("testcase", val)
        // setTestcaseData(val)
      })
  }

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }

  return (
    <>
      <div
        className="carousel Summary-carousel"
      >
        <div className="summary_header">
          <span>Recent Test Execution Summary</span>
        </div>

        {testsuiteData.length !== 0 ? <Slider {...carouselProperties}>
          <div className="summary">
            <div className="summary_chart">
              <Doughnut
                className="dChart h-3 w-3 mb-2"
                data={data}
                plugins={plugins}
                options={{
                  cutout: 45,
                  responsive: true,
                  maintainAspectRatio: true,
                }}
              />
            </div>
            <div className="summary_list">
              <ul>
                <>
                  <li>Test Suite ID</li>
                  <li>TC 101</li>
                  <li>Last Executed</li>
                  <li>28 Jun 7:18 pm</li>
                  {/* <li>{moment(testsuiteData.updatedAt).format("D MMM h:mm a")}</li> */}
                  <li>Test Case Count</li>
                  <li>12/20</li>
                  {/* <li>{`(${testsuiteData.passedCaseCount}/${testsuiteData.totalCase}) Passed`}</li> */}
                </>
              </ul>
            </div>
          </div>

          <div className="summary">
            <div className="summary_chart">
              <Doughnut
                className="dChart h-3 w-3 mb-2"
                data={data1}
                plugins={plugins1}
                options={{
                  cutout: 45,
                  responsive: true,
                  maintainAspectRatio: true,
                }}
              />
            </div>
            <div className="summary_list">
              <ul>
                <>
                  <li>Test Suite ID</li>
                  <li>TC 102</li>
                  <li>Last Executed</li>
                  <li>13 Jul 1:20 pm</li>
                  {/* <li>{moment(testsuiteData.updatedAt).format("D MMM h:mm a")}</li> */}
                  <li>Test Case Count</li>
                  <li>25/44</li>
                  {/* <li>{`(${testsuiteData.passedCaseCount}/${testsuiteData.totalCase}) Passed`}</li> */}
                </>
              </ul>
            </div>
          </div>

          <div className="summary">
            <div className="summary_chart">
              <Doughnut
                className="dChart h-3 w-3 mb-2"
                data={data2}
                plugins={plugins2}
                options={{
                  cutout: 45,
                  responsive: true,
                  maintainAspectRatio: true,
                }}
              />
            </div>
            <div className="summary_list">
              <ul>
                <>
                  <li>Test Suite ID</li>
                  <li>TC 103</li>
                  <li>Last Executed</li>
                  <li>3 Aug 3:00 pm</li>
                  {/* <li>{moment(testsuiteData.updatedAt).format("D MMM h:mm a")}</li> */}
                  <li>Test Case Count</li>
                  <li>44/60</li>
                  {/* <li>{`(${testsuiteData.passedCaseCount}/${testsuiteData.totalCase}) Passed`}</li> */}
                </>
              </ul>
            </div>
          </div>
          <div className="summary">
            <div className="summary_chart">
              <Doughnut
                className="dChart h-3 w-3 mb-2"
                data={data3}
                plugins={plugins3}
                options={{
                  cutout: 45,
                  responsive: true,
                  maintainAspectRatio: true,
                }}
              />
            </div>
            <div className="summary_list">
              <ul>
                <>
                  <li>Test Suite ID</li>
                  <li>TC 104</li>
                  <li>Last Executed</li>
                  <li>4 Sep 2:00 pm</li>
                  {/* <li>{moment(testsuiteData.updatedAt).format("D MMM h:mm a")}</li> */}
                  <li>Test Case Count</li>
                  <li>44/52</li>
                  {/* <li>{`(${testsuiteData.passedCaseCount}/${testsuiteData.totalCase}) Passed`}</li> */}
                </>
              </ul>
            </div>
          </div>
          <div className="summary">
            <div className="summary_chart">
              <Doughnut
                className="dChart h-3 w-3 mb-2"
                data={data4}

                plugins={plugins4}
                options={{
                  cutout: 45,
                  responsive: true,
                  maintainAspectRatio: true,
                }}
              />
            </div>
            <div className="summary_list">
              <ul>
                <>
                  <li>Test Suite ID</li>
                  <li>TC 105</li>
                  <li>Last Executed</li>
                  <li>15 Sep 11:00 am</li>
                  {/* <li>{moment(testsuiteData.updatedAt).format("D MMM h:mm a")}</li> */}
                  <li>Test Case Count</li>
                  <li>48/120</li>
                  {/* <li>{`(${testsuiteData.passedCaseCount}/${testsuiteData.totalCase}) Passed`}</li> */}
                </>
              </ul>
            </div>
          </div>
        </Slider>
          : <div>No Testsuites were executed recently.</div>}
      </div>
    </>
  );
}

export default Summary;
