import Tippy from "@tippyjs/react";
import React, { useState } from "react";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import run from "./../../../../assets/Icons/Run.svg";
// import runbtn from "./../../../../assets/Icons/Runbutton.svg";
import "./Run.css";
import Form from "react-bootstrap/Form";
// import InputGroup from "react-bootstrap/InputGroup";
import chrome from "./../../../../assets/Icons/Chrome.svg";
import safari from "./../../../../assets/Icons/Safari.svg";
import firefox from "./../../../../assets/Icons/Firefox.svg";
import edge from "./../../../../assets/Icons/Edge.svg";
import apple from "./../../../../assets/Icons/iOS.svg";
import android from "./../../../../assets/Icons/Android.svg";
import execute from "./../../../../assets/Icons/run-icon.svg";
// import Badge from "react-bootstrap/Badge";
import { API } from "../../../../global";
import { toast } from "react-toastify";

//import loader

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  // const [value, setValue] = useState("");
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const run_status = async () => {
    handleClose();
    const response = await fetch(`${API}/tests/testsuites/run/${props.id}`, {
      method: "POST",
      body: JSON.stringify(),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then(() => toast.success("Execution Started Successfully"))
      .then(() => Interval());

    const data = await response.json();
    const new_date = Date.now();
    props.status(data.data, new_date);
  };

  let t;
  const Interval = () => {

    t = setInterval(() => {
      const test_id = localStorage.getItem("testsuite-id")
      const app_id = localStorage.getItem("app_id")
      let load = false;
      clearReload(test_id)
      props.getTestsuites(app_id,load);
    }, 3000);
  };
  const clearReload = (id) => {
    fetch(`${API}/tests/testsuites/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((value) => value.json()) //Response Object
      .then((tcs) => {
        if(tcs.data.last_run_status === "passed" || tcs.data.last_run_status === "failed" ){
   clearInterval(t);
        }
      })
  };
  //   const [browser_select, SetBrowser_select] = useState(0);

  //   const browserSelect = () => SetBrowser_select(1);

  return (
    <>
      <Tippy
        placement="top"
        className="tippy-tooltip"
        theme="light"
        content="Run"
      >
        <img
          src={run}
          style={{ cursor: "pointer" }}
          onClick={toggleShow}
          alt="run icon"
         
        />
      </Tippy>

      <Offcanvas show={show} className="run-tsc" onHide={handleClose} {...props}>
        <Offcanvas.Title className="run-title">Run Test Suite</Offcanvas.Title>
        <Form.Label className="run-label mt-4">Select Environment</Form.Label>
        {/* <Form.Select className="run-form">
         <option active value="#">
            Select Environment
          </option>
          <option value="Alpha Testing Environment">
            Alpha Testing Environment
          </option>
          <option value="Dev Environment">Dev Environment</option>
          <option value="Cloud Environment">Cloud Environment</option>
          <option value="Performance Testing Environment">
            Performance Testing Environment
          </option>
          <option value="System Integrating Testing(SIT)">
            System Integrating Testing(SIT)
          </option>
          <option value="User Acceptance Testing(UAT)">
            User Acceptance Testing(UAT)
          </option>
          <option value="Security Testing">Security Testing</option>
        </Form.Select> */}
        <Form.Control
          type="text"
          value="Dev Environment"
          disabled={true}
          className="run-form"
        />
        {/* <select name="select-environment" id="environment">
          <option value="test">Test Environment</option>
          <option value="dev">Dev Environment</option>
          <option value="cloud">Cloud Environment</option>
          <option value="alpha">Alpha Testing Environment </option>
        </select> */}
        <Form.Label htmlFor="basic-url" className="run-label mt-4">
          Select Browser / Mobile OS
        </Form.Label>
        <div className="browser-icons mt-1">
          <button style={{ backgroundColor: "none", border: "none" }}>
            <img src={chrome} style={{ cursor: "pointer" }} alt="chrome icon" />
          </button>

          <img src={safari} style={{ cursor: "pointer" }} alt="safari icon" />
          <img src={firefox} style={{ cursor: "pointer" }} alt="firefox icon" />
          <img src={edge} style={{ cursor: "pointer" }} alt="edge icon" />
          <img src={apple} style={{ cursor: "pointer" }} alt="apple icon" />
          <img src={android} style={{ cursor: "pointer" }} alt="android icon" />
        </div>


        <Form.Label htmlFor="basic-url" className="run-label mt-4">
          Select Version
        </Form.Label>
        <Form.Control
          type="text"
          value="Chrome 100"
          disabled={true}
          className="run-form"
        />

        {/*
        <Form.Group>
          <Form.Select className="run-version-select d-flex" value="#">
            <option value="Chrome 100">Chrome 100</option>
            <option value="Chrome 99">Chrome 99</option>
            <option value="Chrome 98">Chrome 98</option>
            <option value="Chrome 97">Chrome 97</option>
            <option value="Chrome 96">Chrome 96</option>
          </Form.Select>
      </Form.Group> 

        <select name="select-environment mt-2" id="browser-version">
          <option value="chrome-100">Chrome 100</option>
          <option value="chrome-99">Chrome 99</option>
          <option value="chrome-98">Chrome 98</option>
          <option value="chrome-97">Chrome 97</option>
        </select>

        */}
        <div className="run-test justify-content-end">
          <button
            className="run-button"
            onClick={() => {
              run_status();
            }}
          >
            <span>
              <img
                src={execute}
                style={{ color: "white", backgroundColor: "none" }}
                onClick={toggleShow}
                alt="run icon"
              />
            </span>
            Run
          </button>
          <button className="can-button" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </Offcanvas>
    </>
  );
}

export default function RunTestsuite({
  id,
  getTestsuites,
  status
}) {
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          {...props}
          id={id}
          status={status}
          getTestsuites={getTestsuites}
        />
      ))}
    </>
  );
}
