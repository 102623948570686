import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import edit from "../../../../assets/Icons/Edit.svg";
import Form from "react-bootstrap/Form";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "./EditProject.css";
import { useEffect } from "react";
import { API } from "../../../../global";


const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((s) => !s);
  const handleClose = () => {
    setShow(false);
    setName(props.editValue.name);
    setDescription(props.editValue.description);
    setCreatedBy(props.editValue.created_by);
    setUpdatedBy(props.editValue.updated_by);
  };

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [btnDisable, setBtnDisable] = useState(false)
  // const projectLst = props.getProject;

  useEffect(() => {
    setName(props.editValue.name);
    setDescription(props.editValue.description);
    setCreatedBy(props.editValue.created_by);
    setUpdatedBy(localStorage.getItem("user-id"),);
  }, [props.editValue]);

  const EditData = {
    name: name,
    description: description,
    created_by: createdBy,
    updated_by: updatedBy,
  };
  const validate = (values) => {
    let errors = {};

    if (!EditData.name) {
      errors.name = "Cannot be blank";
    }
    if (!EditData.description) {
      errors.description = "Cannot be blank";
    } 
    return errors;
  };
  const editProject = () => {
    validate();
    setBtnDisable(true);
 
    if (Object.keys(validate()).length === 0) {
    fetch(`${API}/admin/project/${props.editValue._id}`, {
      method: "PUT",
      body: JSON.stringify(EditData),
      headers: {
        "content-type": "application/json",

        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then(() => {
      // projectLst()
      handleClose();
      window.location.reload();
      // localStorage.removeItem("ts-edit");
    });
  }else{
    setFormErrors(validate());
    setBtnDisable(false)
  }
  };

  return (
    <div>
      <Tippy
        placement="top"
        className="tippy-tooltip"
        theme="light"
        content="Edit"
      >
        <img
          src={edit}
          onClick={toggleShow}
          className="action"
          style={{ cursor: "pointer" }}
          alt="edit icon"
        />
      </Tippy>

      <Offcanvas

        className="edit-project-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="edit-pj">
            <span>Edit Project</span>
            {/* <span
              onClick={handleClose}
              style={{
                marginLeft: "200px",
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "normal",
              }}
            >
              X
            </span> */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form.Label
          className="edit-pj-label"
          htmlFor="basic-url"
        >
          Project Name
        </Form.Label>
        <span className="edit-project">
          <Form.Control
            value={name}
            type="text"
            placeholder="Enter a project name"
            className="ed-story-box"
            onChange={(event) => {
              setName(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,name:"Name should not be blank"}
                return changes
               })
             }  
            }}
          />
          {formErrors.name && (
            <span className="error">{formErrors.name}</span>
          )}
        </span>

        <br />
        <Form.Label
          className="edit-pj-label"
          htmlFor="basic-url"
        >
          Project Description
        </Form.Label>
        <span className="edit-project">
          <Form.Control
            type="text"
            placeholder="Enter a project description"
            className="ed-story-box"
            onChange={(event) => {
              setDescription(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,description:"Description should not be blank"}
                return changes
               })
             }  
            }}
            value={description}
          />
          {formErrors.description && (
            <span className="error">{formErrors.description}</span>
          )}
        </span>

        <span className="edit-project-button">
          <button
            type="button"
            disabled={btnDisable}
            className="edit-pj-button"
            onClick={() => editProject()}
          >
            Save
          </button>
          <button
            type="button"
            className="cancel-pj-edit"
            onClick={() => handleClose()}
          >
            Cancel
          </button>
        </span>
      </Offcanvas>
    </div>
  );
}

export default function EditProject({ editApp, getProject }) {
  console.log(editApp);
  useEffect(() => { }, [editApp]);
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          {...props}
          placement={props.placement}
          editValue={editApp}
          getProject={getProject}
        />
      ))}
    </>
  );
}
