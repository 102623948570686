import React, { useEffect, useState } from "react";
import Navbar from "../../Layout/Navbar/Navbar";
import "./TestcaseList.css";
import Table from "react-bootstrap/Table";
import { GoPlus } from "react-icons/go";
import filter from "./../../../assets/Icons/Filter.svg";
import clone from "./../../../assets/Icons/Clone.svg";
import edit from "./../../../assets/Icons/Edit.svg";
import publish from "./../../../assets/Icons/published.svg";
import unpublish from "./../../../assets/Icons/unpublished.svg";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import DeleteTcButton from "./../DeleteTestcase/DeleteTestcase";
import moment from "moment";
import RunTestcase from "./../TestcaseExecution/Run/Run";
import { API } from "./../../../global";
// import TestExecution from "./../ExecutionLoader/Loader";
// import RunTcButton from "./../TestcaseExecution/RunLogs/RunLogs";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { Oval } from "react-loader-spinner";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { SlClose } from "react-icons/sl";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import report from "./../../../assets/Icons/Report.svg";
// import Pagination from '@mui/material/Pagination';


export default function TestcaseList() {
  const navigate = useNavigate();
  const [testcaselist, setTestcaselist] = useState([]);
  const [testsuite, setTestsuite] = useState([]);
  const [applist, setApplist] = useState([]);
  // const [rundata, setRundata] = useState(false);
  const [runvalue, setRunvalue] = useState("");
  const [exedate, setExedate] = useState("");
  const [unid, setUnid] = useState("");
  const [tsid, setTsid] = useState("");
  // const [appid, setAppid] = useState("");
  const [app_selected, setApp_selected] = useState("");
  const [loading, setLoading] = useState(false);
  const [testsuite_selected, setTestsuite_selected] = useState("");
  const [sort, setSort] = useState("asc");
  // const [testsuite_id, setTestsuite_id] = useState("");
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [page, setPage] = React.useState(1);
  // To get all the testcases from Database

  

  const getTestcases = async () => {

    await fetch(`${API}/tests/testcases/get`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((value) => value.json())
      // .then((data) => console.log(data.data)) //Response Object
      .then((tcs) => {
       tcs.data.length > 0 && setTsid(+(tcs.data[tcs.data.length - 1].test_case_id) + 1 );
   
        // console.log(tcs.data[tcs.data.length - 1].test_case_id)
        setLoading(false);
      });
    // .then(() => setTimeout(() => window.location.reload(), 30000));
  };

  //to get application in the dropdown



  const project_id = localStorage.getItem("project-id");
  const applicationId = localStorage.getItem('app_id')
  const app_name = localStorage.getItem("app-id");

  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/qe/testcase/pr/${project_id}`);
    }
  };

  useEffect(() => {

    get_project_id();
    getTestcases();
    // testcasesForPagination()
    if(project_id){
    
      getApplication()
      getTestcasesByprojectId(project_id)
      testsuitesByprojectId(project_id)
      testsuitesByprojectId()
    }
    if (project_id && applicationId) {
     
      getTestcasesByprojectId(project_id,applicationId)
      testsuitesByAppId(applicationId);
    }
  
    // eslint-disable-next-line
  }, [project_id]);

  // get application by project id
  const getApplication = () => {

    setApplist([]);
    fetch(
      `${API}/admin/application/${localStorage.getItem("project-id")}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        let newData = [...app.data, {name:'All'}]
        app.data.length > 0 ? setApplist(newData.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setApplist([]);
      })

  };

  // const handleChangePage = (page) => {
  //   setPage(page);
  //   setLoading(true);
  //   setTestcaselist([]);
  //   fetch(
  //     `${API}/tests/testcases/get?page=${page}&limit=10`,
  //     {
  //       method: "GET",
  //       headers: {
  //         authorization: `${localStorage.getItem("access-key")}`,
  //       },
  //     }
  //   )
  //     .then((val) => val.json())
  //     .then((test) => setTestcaselist(test.data))
  //     .then(() => setLoading(false));
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

// testcases pagination
  // const testcasesForPagination = () => {
  //   setLoading(true);
  //   // setTestcaselist([]);
  //   fetch(
  //     `${API}/tests/testcases/get?page=1&limit=7`,
  //     {
  //       method: "GET",
  //       headers: {
  //         authorization: `${localStorage.getItem("access-key")}`,
  //       },
  //     }
  //   )
  //     .then((val) => val.json())
  //     .then((test) => setTestcaselist(test.data))
  //     .then(() => setLoading(false));
  // };

  // testcases by Project and ApplicationId
  const getTestcasesByprojectId = (proId,appid,load, name) => {
    // setTestcaselist([])
    setLoading(load);
    setApp_selected('')
console.log(proId, appid, name);
    if (proId && appid === undefined) {
       fetch(`${API}/tests/testcases/application/?project_id=${proId}`, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }) //promise
      .then((val) => val.json())
      .then((test) => {
        console.log(test.data,"data")
        console.log(test.data.length,"data")
        const testData = test.data.sort((a,b)=> a.test_case_id - b.test_case_id)
      
        setTestcaselist(testData);
      })
      .then(() => {
        setTestsuite_selected("");
        setApp_selected("");
      })
      .then(() => setLoading(false));
    }
    else if(proId && appid){
      if(name==='All'){
        fetch(`${API}/tests/testcases/application/?project_id=${proId}`, {
          method: "GET",
          headers: {
            authorization: `${localStorage.getItem("access-key")}`,
          },
        }) //promise
        .then((val) => val.json())
        .then((test) => {
          console.log(test.data,"data")
          console.log(test.data.length,"data")
          const testData = test.data.sort((a,b)=> a.test_case_id - b.test_case_id)
        
          setTestcaselist(testData);
        })
        .then(() => {
          setTestsuite_selected("");
          setApp_selected("");
        })
        .then(() => setLoading(false));
      }else{
     fetch(`${API}/tests/testcases/application/?application_id=${appid}&&project_id=${proId}`, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }) //promise
      .then((val) => val.json())
      .then((test) => {
        console.log(test.data,"data")
        console.log(test.data.length,"data")
        const testData = test.data.sort((a,b)=> a.test_case_id - b.test_case_id)
        setTestcaselist(testData);
      })
      .then(() => {
        setTestsuite_selected("");
        setApp_selected("");
      })
      .then(() => setLoading(false));
    }
  }
  };


  // get testsuite by project id
  const testsuitesByprojectId = () => {
    setLoading(true);
    setTestsuite([]);
    fetch(`${API}/tests/testsuite/application/?project_id=${project_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((test) =>{
        let newData=[...test.data, {name:'All'}];
        test.data.length > 0 ? setTestsuite(newData.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setTestsuite([])
      }
      )
      .then(() => setLoading(false));
  };

  // get testsuite by app id
  const testsuitesByAppId = (id, name) => {

    setLoading(true);
    setTestsuite([]);
    if(name==='All'){
      testsuitesByprojectId() 
      // fetch(`${API}/tests/testsuite/application/?application_id=${id}`, {
      //   method: "GET",
      //   headers: {
      //     authorization: `${localStorage.getItem("access-key")}`,
      //   },
      // })
      //   .then((val) => val.json())
      //   .then((test) =>{
      //     let newData=[...test.data, {name:'All'}];
      //     test.data.length > 0 ? setTestsuite(newData.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setTestsuite([])
  
      //   }
      //   )
      //   .then(() => setLoading(false));
    }else{
    fetch(`${API}/tests/testsuite/application/?application_id=${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((test) =>{
        let newData=[...test.data, {name:'All'}];
        test.data.length > 0 ? setTestsuite(newData.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setTestsuite([])

      }
      )
      .then(() => setLoading(false));
    }
  };


  const testcasesBytestsuiteId = (id, name) => {
    setLoading(true);
    setTestcaselist([]);

    if(name==='All'){
      fetch(`${API}/tests/testsuite/application/?project_id=${project_id}`, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      })
        .then((val) => val.json()) 
        .then((test) =>{
          let newData = [...test.data, {name:'All'}]
          test.data.length > 0 ? setTestsuite(newData.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setTestsuite([])
        }
        )
        .then(() => setLoading(false));
    }else{
    
     fetch(`${API}/tests/testsuites/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((test) => {
        // console.log("testcases", test.data.test_cases);
        const testData = test.data.test_cases.sort((a,b)=> a.test_case_id - b.test_case_id)
      
        setTestcaselist(testData);
        // setTestcaselist(test.data.test_cases);
      })
      .then(() => setLoading(false));
    }
    // .then(() => setTestsuite_id(""));
  };

  const status = (val, exec_date) => {
    if (val === "Pending") {
      // setRundata(true);
      setTimeout(() => {
        // setRundata(false);
      }, 10000);
      setExedate(exec_date);
    } else {
      setRunvalue(val);
      setExedate(exec_date);
      setInterval(() => {
        // setRundata(false);
      }, 7500);
    }
  };
  const getId = (id) => {
    setUnid(id);
    localStorage.setItem("test-id",id)
    setRunvalue("");
  };

  const sorting = (order) => {
    if (sort === "asc") {
      const sorted = [...testcaselist].sort((a, b) => 
        a[order] > b[order] ? 1 : -1
      );
      setTestcaselist(sorted);
      setSort("dsc");
    }
    if (sort === "dsc") {
      const sorted = [...testcaselist].sort((a, b) => 
        a[order] > b[order] ? 1 : -1
      );
      setTestcaselist(sorted);
      setSort("asc");
    }
  }


  const [expand, setExpand] = useState(false);

  let testlist;
    console.log(testcaselist, 'test');
  let top_green = (
    <Container fluid className="position-fixed TC-head">
      <Row className="test-row">
        <Col sm={5}>
          <div className="d-flex TC-first-head justify-content-start">
            Test Cases
          </div>
        </Col>

        <Col sm={7}>

          <div className="d-flex justify-content-end test-btns">
            <div className="btn-group TC-drop-menu">
              <label className="TC-bulk position-absolute">Action</label>
              <button
                type="button"
                className="TC-bulk-btn me-3 dropdown-toggle"
                data-bs-toggle="dropdown"
                size="sm"
                aria-expanded="false"
              >
                Bulk Action
              </button>
              <ul className="dropdown-menu TC-drop">
                <li className="dropdown-item" style={{ fontSize: "14px" }}>
                  Run
                </li>
                <li className="dropdown-item" style={{ fontSize: "14px" }}>
                  Publish
                </li>
              </ul>
            </div>

            <div className="btn-group TC-app">
              <label className="TC-App position-absolute">Application</label>
              <button
                type="button"
                className="TC-bulk-btn me-3 dropdown-toggle TC-drop-app"
                data-bs-toggle="dropdown"
                size="sm"
                aria-expanded="false"
              >
                {app_selected ? app_selected : app_name ? app_name : '- - Select - -'}
              </button>
              <ul className="dropdown-menu dScroll">
                {applist.length === 0 ? <li
                  className="dropdown-item text-center"
                >
                  No Application found
                </li> : applist.map((app, i) => {
                  <li>All</li>
                  return (
                    <li
                      key={i}
                      className="dropdown-item"
                      onClick={() => {
                        getTestcasesByprojectId(project_id,app._id, app.name);
                        setApp_selected(app.name);
                        testsuitesByAppId(app._id, app.name);
                        localStorage.setItem("app-id", app.name);
                        localStorage.setItem("app_id", app._id)
                      }}
                    >
                      {app.name}
                    </li>
                  );
                })}

              </ul>
            </div>

            <div className="btn-group drop-TS">
              <label className="TC-suite position-absolute">Test Suites</label>
              <button
                type="button"
                className="TC-bulk-btn me-3 dropdown-toggle"
                data-bs-toggle="dropdown"
                size="sm"
                aria-expanded="false"
              >
                {testsuite_selected ? testsuite_selected : "- - Select - -"}
              </button>
              <ul className="dropdown-menu dScroll">
                {/* <li
                className="dropdown-item"
                onClick={() => {
                  getTestcasesByprojectId();
                  setTestsuite_selected("All");
                }}
              >
                All
              </li> */}

                {testsuite === 0
                  ? "No Testsuite found"
                  : testsuite.map((test, i) => {
                    return (
                      <li
                        key={i}
                        className="dropdown-item"
                        onClick={() => {
                          setTestsuite_selected(test.name);
                          // setTestsuite_id(test._id);
                          testcasesBytestsuiteId(test._id, test.name);
                        }}
                      >
                        {test.name}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="test-case-create-btn text-start"
              >
                <span>
                  <GoPlus className="add-icon me-1" />
                </span>
                Create Test Cases
              </Dropdown.Toggle>
              <div className="CTC_ddown">
              <Dropdown.Menu
                variant="light"
                style={{ width: "150px", marginRight: "70px" }}
              >
                <Dropdown.Item
                  type="button"
                  className="test-case-drop-btn-ct mb-1 text-center"
                  onClick={() => {
                    localStorage.setItem(
                      "testcase-id",
                      tsid === "" ? 1 : tsid
                    );
                    navigate("/qe/testcase/create-testcase");
                  }}
                >
                  Single Test Case
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  style={{ marginTop: '3px' }}
                  className="test-case-drop-btn-ct mb-1 text-center"
                  onClick={() => {
                    localStorage.setItem(
                      "testcase-id",
                      tsid === "" ? 1 : tsid
                    );
                    navigate("/qe/testcase/generate-testcase");
                  }}
                >
                  Generate Test Case
                </Dropdown.Item>
              </Dropdown.Menu>
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Container>
  )

  if (!project_id && testcaselist.length === 0) {
    testlist = (
      <>
        {/* {top_green} */}
        <div style={{ marginTop: "20%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
          <div> <AiOutlineInfoCircle size="30px" color="#9F9F9F" /></div>
          <h6 style={{ color: "#9F9F9F" }}>Please Select a Project</h6>
        </div>
      </>
    );
  } else if (project_id && loading) {
    testlist = (
      <div className="tc-loader">
        <Oval
          height={80}
          width={1800}
          color="#805382"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#F0E3F1#AB78AE"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }
     else if (project_id && testcaselist.length === 0) {
      testlist = (
        <>
          {top_green}
          <div className="tc-not-found">
            <div><SlClose size="30px" color="#9F9F9F" /></div>
            <h6 style={{ color: "#9F9F9F" }}>No Testcase Found</h6>
          </div>
        </>
      );
    } 
  else {
    testlist = (
      <>
        {top_green}
        <Table className="testcase-table">
          <thead
            className="align-middle TC-thead"
            style={{
              backgroundColor: "#E6C6E840",
              color: "#805382",
            }}
          >
            <tr className="align-middle">
              <th style={{ borderRight: "2px solid #805382", width: "13%", paddingLeft: "40px", height: "2px" }}>
                <input type="checkbox" style={{ marginRight: "6px", zoom: "1" }} className="test" />
                <span>ID</span>
                <span onClick={() => {sorting(testcaselist._id) || setExpand(!expand) }}>
                  {!expand ? <MdArrowDropDown size={15} /> : <MdArrowDropUp size={15} />}
                </span>
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                Name
                <span onClick={() => {sorting(testcaselist._id) || setExpand(!expand) }}>
                  {!expand ? <MdArrowDropDown size={15} /> : <MdArrowDropUp size={15} />}
                </span>
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                Type
                <span onClick={() => { sorting(testcaselist._id) || setExpand(!expand)}}>
                  {!expand ? <MdArrowDropDown size={15} /> : <MdArrowDropUp size={15} />}
                </span>
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                User Story
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
              Last Run Status
                <span onClick={() => {sorting(testcaselist._id) || setExpand(!expand) }}>
                  {!expand ? <MdArrowDropDown size={15} /> : <MdArrowDropUp size={15} />}
                </span>
              </th>
              {/* <th style={{ borderRight: "2px solid #805382", width: "13%", borderHieght:"5px" }}>
            Updated By
            <span onClick={() => setExpand(!expand)}>
                  {!expand ? <MdArrowDropDown size={15} /> : <MdArrowDropUp size={15} />}
                </span>
          </th> */}
              <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                Last Executed
                <span onClick={() => {sorting(testcaselist._id) || setExpand(!expand) }}>
                  {!expand ? <MdArrowDropDown size={15} /> : <MdArrowDropUp size={15} />}
                </span>
              </th>
              <th style={{ width: "20%" }}>
                <span className="TC-action ">Actions</span>
                <img
                  src={filter}
                  className="TC-filter"
                  alt="filter icon"
                />
              </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {testcaselist.map((testcase, i) => {
              return (
                <tr style={{ height: "10px" }} key={i}>
                  <td>
                    <input type="checkbox" style={{ margin: "5px", zoom: "1" }} />{" "}
                    TC{testcase.test_case_id}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={
                        testcase.published === true ? "Published" : "Unpublished"
                      }
                    >
                      <img
                        src={testcase.published === true ? publish : unpublish}
                        style={{ marginRight: "10px" }}
                        alt="publish icon"
                      />
                    </Tippy>

                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={testcase.test_case_name}
                    >
                      <span data-tip="hello world">
                        {testcase.test_case_name}
                      </span>
                    </Tippy>
                  </td>
                  <td>{testcase.test_case_type}</td>
                  <td>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={testcase.user_story}
                    >
                      <span data-tip="hello world">{testcase.user_story}</span>
                    </Tippy>
                  </td>
                  <td
                    style={{
                      color:
                        testcase.last_run_status === "passed" ||
                          (runvalue === "passed" && testcase._id === unid)
                          ? "#7EB965"
                          : testcase.last_run_status === "failed" ||
                            (runvalue === "failed" && testcase._id === unid)
                            ? "#DC3545"
                            : "#eab676",

                      fontWeight: "bold",
                    }}
                  >
                    {/* <TestExecution status={status} /> */}
                    {/* {rundata === false && runvalue === "" ? (
                    testcase.last_run_status
                  ) : rundata === true && testcase._id === unid ? (
                    <TestExecution status={status} />
                  ) : runvalue !== "" && testcase._id === unid ? (
                    testcase.last_run_status
                  ) : (
                    testcase.last_run_status
                  )} */}
                    {/* testcase.last_run_status === "queued" ||
                    testcase.last_run_status === "in progress" ||
                    testcase.last_run_status === "passed" ||
                    testcase.last_run_status === "failed" ? (
                    testcase.last_run_status */}
                    {/* {rundata === true &&
                    testcase._id === unid &&
                    testcase.last_run_status !== "queued" &&
                    testcase.last_run_status !== "in progress" ? (
                    <TestExecution status={status} />
                  ) : (
                    testcase.last_run_status
                  )} */}
                    {testcase.last_run_status}
                    {/* {testcase._id === unid
                    ? console.log("status", testcase.last_run_status)
                    : ""} */}
                    {/* {testcase._id === unid
                    ? testcase.last_run_status
                    : testcase.last_run_status} */}
                  </td>
                  {/* <td style={{ width: "150px" }}>
                {testcase.updated_by.Ad_username}
              </td> */}
                  <td>
                    {testcase.last_run_status === "Pending"
                      ? "------"
                      : exedate === ""
                        ? moment(testcase.last_run).format("D MMM h:mm a")
                        : exedate !== "" && testcase._id === unid
                          ? moment(exedate).format("D MMM h:mm a")
                          : moment(testcase.last_run).format("D MMM h:mm a")}
                  </td>
                  <td>
                    <div className="TC-action-icons">
                      <Tippy
                        placement="top"
                        className="tippy-tooltip"
                        theme="light"
                        content="Clone"
                      >
                        <img
                          src={clone}
                          className="action"
                          style={{ cursor: "pointer" }}
                          alt="clone icon"
                          onClick={() => {
                            localStorage.setItem(
                              "testcase-id",
                              testcaselist[testcaselist.length - 1].test_case_id
                            );
                            navigate(
                              `/qe/testcase/clone-testcase/${testcase._id}`
                            );
                          }}
                        />
                      </Tippy>
                      <button
                        style={{ background: "none", border: "none" }}
                        onClick={() => getId(testcase._id)}
                      >
                        <RunTestcase
                          status={status}
                          id={testcase._id}
                          published={testcase.published}
                          getTestcases={getTestcasesByprojectId}
                          testcase={testcase}
                        />
                      </button>

                      <Tippy
                        placement="top"
                        className="tippy-tooltip"
                        theme="light"
                        content="Edit"
                      >
                        <img
                          src={edit}
                          onClick={() =>
                            navigate(`/qe/testcase/edit-testcase/${testcase._id}`)
                          }
                          className="action"
                          style={{ cursor: "pointer" }}
                          alt="edit icon"
                        />
                      </Tippy>
                      {/* <RunTcButton id={testcase._id} /> */}
                      <button style={{ background: "none", border: "none" }}
                         disabled ={testcase.last_run_status === "Pending" ? true : false}
                         onClick={() =>{
                            localStorage.setItem("ts-id",testcase.test_case_id)
                              navigate(`/qe/testcase/report/${testcase._id}`)
                            }}
                         >
                      <Tippy placement="top" 
                          className="tippy-tooltip"
                        theme="light"
                         content={
                          testcase.last_run_status === "Pending" ? "Please execute the testcase" : "Report"
                          }
                          >
                       
                          <img
                            src={report}
                            style={{ cursor: "pointer" }}
                              className="action"
                            alt="report icon"
                            
                          />
                       
                        </Tippy>
                        </button>
                      <DeleteTcButton id={testcase._id} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <>
      <div className="">
        <Navbar />
        <ReactTooltip />
        <div>{testlist}</div>
        {/* <div className='page-head fixed-bottom'>
            <Pagination 
             count={10} 
             variant="outlined" 
             shape="rounded"
             page={page}
             onPageChange={()=> handleChangePage(page,)}
              rowsPerPageOptions={[10, 25, 100]}
             pageSizeOptions={[5, 10, 25]}
            />
        </div> */}
      </div>

    </>
  );
}
