import React, { useEffect, useState } from "react";
import Header from "../../Layout/Navbar/Navbar";
import "./TestsuiteList.css";
import Table from "react-bootstrap/Table";
import report from "./../../../assets/Icons/Report.svg";
import EditTsButton from "../EditTestsuite/EditTestsuite";
import DeleteTsButton from "../DeleteTestsuite/DeleteTestsuite";
import CreateTsButton from "../CreateTestsuite/CreateTestsuite";
import ScheduleTestsuite from "../ScheduleTestSuite/ScheduleTestsuite";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import moment from "moment";
import { API } from "../../../global";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import RunTestsuite from "../Testsuite Execution/Run/Run";
import { Oval } from "react-loader-spinner";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { SlClose } from "react-icons/sl";
import filter from "./../../../assets/Icons/Filter.svg";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";

export default function TestsuiteList() {
  const navigate = useNavigate();
  const [testsuitelogs, setTestsuitelogs] = useState([]);
  const [runvalue, setRunvalue] = useState("");
   const [exedate, setExedate] = useState("");
  const [unid, setUnid] = useState("");
  const[suite, setSuite] = useState([]);
  const [app_selected, setApp_selected] = useState("");
  const [applist, setApplist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("asc");


  const app_name = localStorage.getItem("app-id");
  const project_id = localStorage.getItem("project-id");
  const app_id = localStorage.getItem("app_id");

  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/qe/testsuite/pr/${project_id}`);
    }
  };

  useEffect(() => {
    get_project_id();
    getApplication();
    getTestsuitesByProjectId(app_id);
    getAllTestsuites()
    // eslint-disable-next-line
  }, [project_id]);

  // let logs = [];
  // let list = [];
  const getTestsuitesByProjectId = (appid,load) => {
    // list = [];
    // logs = [];
    // setTestsuitelogs([]);
    setLoading(load);
    setApp_selected("");
    fetch(
      project_id && appid === null
        ? `${API}/tests/testsuite/application/?project_id=${project_id}`
        : `${API}/tests/testsuite/application/?project_id=${project_id}&application_id=${appid}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    ) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        setSuite(ts.data); console.log(ts.data, "TS");
        setTestsuitelogs(ts.data);
         const suiteData = ts.data.filter((test)=> test.last_run_status === "Pending" || test.last_run_status === "queued" || test.last_run_status === "in progress" )
        getTestsuiteLogs(suiteData,appid)
        // setTestsuitelist(ts.data);
        // list = [...ts.data];
      })
      // .then(() => getTestsuiteLogs());
  };

  const getTestsuiteLogs = (suiteList,appid) => {
    
    // let testsuite = suiteList.filter(h => h.last_run_status === 'Pending')
    // setSuite(testsuite)

    if (project_id && appid === null) {
     
      fetch(`${API}/tests/testsuite/testlog/?project_id=${project_id}`, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }) //promise
        .then((data) => data.json()) //Response Object
        .then((ts) => {
          const sorted =  ts.data.sort((a,b)=> new Date(b.updated_at) - new Date(a.updated_at))
          const unique = sorted.filter(
            (obj, index) =>
              ts.data.findIndex((item) => item.testsuite_id === obj.testsuite_id) !== index
          );
          console.log(unique,'fgf');

          

          console.log(sorted,"ascending");

// let dummy = [...unique, ...ts.data]
// const duplicates = ts.data.filter((item, index) => index != ts.data.indexOf(item));
// console.log(dummy, 'dummy');
// console.log(duplicates,'jgkjg');
const unique2 = sorted.filter((obj, index) => {
  return index === sorted.findIndex(o => obj.testsuite_id === o.testsuite_id)
}); 
console.log(unique2,'uniq2');
let hhh = [...suiteList, ...unique2]

// const testData = hhh.sort((a,b)=> a.testsuite_id - b.testsuite_id)
const unique3 = hhh.filter((obj, index) => {
  return index === hhh.findIndex(o => obj.testsuite_id === o.testsuite_id);
});

const testData = unique3.sort((a,b)=> a.testsuite_id - b.testsuite_id)
const unique4 = testData.filter((obj, index) => {
  return index === testData.findIndex(o => obj.testsuite_id === o.testsuite_id);
});

          setTestsuitelogs(unique4)
          // setSuitelog(ts.data)
        })

        .then(() => setLoading(false));
      // console.log(logs);
    } else if (project_id && appid) {
    
      fetch(
        `${API}/tests/testsuite/testlog/?project_id=${project_id}&application_id=${appid}`,
        {
          method: "GET",
          headers: {
            authorization: `${localStorage.getItem("access-key")}`,
          },
        }
      ) //promise
        .then((data) => data.json()) //Response Object
        .then((ts) => {
          console.log(ts.data,"logsdata")
          const sorted =  ts.data.sort((a,b)=> new Date(b.updated_at) - new Date(a.updated_at))
          const unique = sorted.filter((obj, index) =>{
           console.log(obj.testsuite_id,"obj");
         return ts.data.findIndex((item) => item.testsuite_id === obj.testsuite_id) === index

          })
          console.log(unique,"uni")
         

          // console.log(sorted,"ascending");

// let dummy = [...sorted]
// const duplicates = ts.data.filter((item, index) => index != ts.data.indexOf(item));
// console.log(dummy, 'dummy');
// console.log(duplicates,'jgkjg');
const unique2 = sorted.filter((obj, index) => {
  return index === sorted.findIndex(o => obj.testsuite_id === o.testsuite_id);
});
// console.log(unique2,'uniq2');
let hhh = [...suiteList,...unique2,]
// console.log(hhh,"uni34")
const unique3 = hhh.filter((obj, index) => {
  return index === hhh.findIndex(o => obj.testsuite_id === o.testsuite_id);
});
// console.log(unique3,"unique3")
const testData = unique3.sort((a,b)=> a.testsuite_id - b.testsuite_id)
const unique4 = testData.filter((obj, index) => {
  return index === testData.findIndex(o => obj.testsuite_id === o.testsuite_id);
});
// console.log(unique4,"un4")
          setTestsuitelogs(unique4) 
          // setSuitelog(ts.data)

        })

        .then(() => setLoading(false));
      // console.log(logs);
    }
  };
  //to get application in the dropdown

  const getApplication = () => {
    setApplist([]);
     fetch(
      `${API}/admin/application/${localStorage.getItem("project-id")}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        app.data.length > 0 ? setApplist(app.data.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)): setApplist([]);
      })
    
  };
  const getAllTestsuites = () => {
    fetch(
         `${API}/tests/testsuite/all`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    ) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        ts.data.length > 0 ? localStorage.setItem('tsID',ts.data[ts.data.length - 1].testsuite_id) : localStorage.setItem('tsID',0) ;
      })
  };

  const status = (val, exec_date) => {
    if (val === "Pending") {
      //  setRundata(true);
      setTimeout(() => {
        //   setRundata(false);
      }, 60000);
      setExedate(exec_date);
    } else {
      setRunvalue(val);
      setExedate(exec_date);
      setInterval(() => {
        //    setRundata(false);
      }, 7500);
    }
  };

  const getId = (test) => {
 
    let testsuiteId = test.test_suite_id ? test.test_suite_id._id : test._id;
 
    setUnid(testsuiteId);
    localStorage.setItem("testsuite-id",testsuiteId)
    setRunvalue("");
  };

  //Sorting func

  const sorting = (order) => {
    if (sort === 'asc') {
      const sorted = [...testsuitelogs].sort((a, b) =>
        a[order] > b[order] ? 1 : -1
      );
      setTestsuitelogs(sorted);
      setSort('dsc');
    }
    if (sort === 'dsc') {
      const sorted = [...testsuitelogs].sort((a, b) =>
        a[order] > b[order] ? 1 : -1
      );
      setTestsuitelogs(sorted);
      setSort("asc");
    }
  }

  let testlist;

  const [expand, setExpand] = useState(false);

  let top_green = (
    <Container fluid>
      <Row className="testsuite-test-row">
        <Col sm={5}>
          <div className="d-flex first-head justify-content-start">
            Test Suites
          </div>
        </Col>

        <Col sm={7} className="qe_testsuite">
          <div className="d-flex justify-content-end TS-test-btns">
            <div className="btn-group">
              <label className="TS-bulk position-absolute">Action</label>
              <button
                type="button"
                className="TS-bulk-btn me-3 dropdown-toggle"
                data-bs-toggle="dropdown"
                size="sm"
                aria-expanded="false"
              >
                Bulk Action
              </button>
              <ul className="dropdown-menu">
                <li className="dropdown-item" style={{ fontSize: "14px" }}>
                  Run
                </li>
                <li className="dropdown-item" style={{ fontSize: "14px" }}>
                  Publish
                </li>
              </ul>
            </div>
            {/* application dropdown */}
    
              <div className="btn-group">
              <label className="TS-App position-absolute ">Application</label>
              <div className="TS-bulk-btn me-3">
              <button
                type="button"
                className="TS-app-btn"
                data-bs-toggle="dropdown"
                size="sm"
                aria-expanded="false"
              >
                {app_selected
                  ? app_selected
                  : app_name
                  ? app_name
                    : "--Select--"}
             
                </button>
                <span className="dropdown-toggle"></span>
            
              <ul className="dropdown-menu">
                {applist.length === 0 ? (
                  <li className="dropdown-item">No Application found</li>
                ) : (
                  applist.map((app, i) => {
                    return (
                      <li
                        key={i}
                        className="dropdown-item"
                        onClick={() => {
                          getTestsuitesByProjectId(app._id);
                          setApp_selected(app.name);
                          localStorage.setItem("app-id", app.name);
                          localStorage.setItem("app_id", app._id);
                        }}
                      >
                        {app.name}
                      </li>
                    );
                  })
                )}
              </ul>
              </div>
              </div>
            {/* application dropdown end */}
            <Dropdown>
              <CreateTsButton
                getTestsuites={getTestsuitesByProjectId}
                className="test-suite-create-btn"
              />
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Container>
  );

  if (!project_id && suite.length === 0) {
    testlist = (
      <>
        {/* {top_green} */}
        <div style={{ marginTop: "20%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center" }}>
          <div>
            {" "}
            <AiOutlineInfoCircle size="30px" color="#9F9F9F" />
          </div>
          <h6 style={{ color: "#9F9F9F" }}>Please Select a Project</h6>
        </div>
      </>
    );
  } else if (project_id && loading) {
    testlist = (
      <div className="tc-loader">
        <Oval
          height={80}
          width={1800}
          color="#805382"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#F0E3F1#AB78AE"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }
   else if (project_id === 0 && testsuitelogs.length === 0) {
    testlist = (
      <>
        {top_green}
        <div className="testsuite_table_container">
          <Table className="testsuite-table">
            <thead
              style={{
                backgroundColor: "#E6C6E840",
               
                color: "#805382",
              }}
              className="align-middle TSC-thead"
            >
              <tr>
                <th
                  style={{
                    borderRight: "2px solid #805382",
                    fontSize: "14px",
                    paddingLeft: "40px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                  <input type="checkbox" style={{ margin: "6px", zoom: "1" }} className="test"/>
                  ID
                  {/* ID */}
                  <span onClick={() => {sorting(testsuitelogs._id) || setExpand(!expand) }}>
                    {!expand ? 
                      <MdArrowDropDown size={15} />
                    : 
                      <MdArrowDropUp size={15} />
                    }
                  </span>
                  {/* <span className="dropdown-toggle ms-2"
                  data-bs-toggle="dropdown"
                   size="sm"
                   aria-expanded="true"></span> */}
                </th>
                <th
                  style={{
                    borderRight: "2px solid #805382",
                    fontSize: "14px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                   Name
                  <span onClick={() => { sorting(testsuitelogs._id) || setExpand(!expand) }}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th
                  style={{
                    borderRight: "2px solid #805382",
                    width:"13%",
                    fontSize: "14px",
                  }}
                  className="align-middle"
                >
                  No of Test Cases
                  <span onClick={() =>  {sorting(testsuitelogs._id) || setExpand(!expand)}}>
                    {!expand ? 
                      <MdArrowDropDown size={15} />
                    : 
                      <MdArrowDropUp size={15} />
                    }
                  </span>
                </th>
                <th
                  style={{
                    borderRight: "2px solid #805382",                 
                    fontSize: "14px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                  Last Run Status
                </th>
                <th
                  style={{
                    borderRight: "2px solid #805382",
                    fontSize: "14px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                  Version
                  <span onClick={() =>  {sorting(testsuitelogs._id) || setExpand(!expand) }}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>

                <th
                  style={{
                    borderRight: "2px solid #805382",                  
                    fontSize: "14px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                  Last Executed
                  <span onClick={() =>  {sorting(testsuitelogs._id) || setExpand(!expand) }}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th style={{  width:"23%"}}>
                  <span className="TC-action ">Actions</span>
                  <img src={filter} className="TC-filter" alt="filter icon" />
                </th>
              </tr>
            </thead>
          </Table>
        </div> 
         <div style={{ marginTop: "15%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
        <div>
            {" "}
            <SlClose size="30px" color="#9F9F9F" />
          </div>
          <h6 style={{ color: "#9F9F9F" }}>No Testsuite found</h6>
        </div> 
      
      </>
    );
  } 
  else {
    testlist = (
      <>
        {top_green}
        <div className="testsuite_table_container">
          <Table className="testsuite-table">
            <thead
              style={{
                backgroundColor: "#E6C6E840",
               
                color: "#805382",
              }}
              className="align-middle TSC-thead"
            >
              <tr>
                <th
                  style={{
                    borderRight: "2px solid #805382",
                    fontSize: "14px",
                    paddingLeft: "40px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                  <input type="checkbox" style={{ margin: "6px", zoom: "1" }} />
                  ID   
                  <span onClick={() => {sorting(testsuitelogs._id) || setExpand(!expand)}}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th
                  style={{
                    borderRight: "2px solid #805382",
                    fontSize: "14px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                   Name
                  <span onClick={() => { sorting(testsuitelogs._id) || setExpand(!expand) }}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th
                  style={{
                    borderRight: "2px solid #805382",
                    width:"13%",
                    fontSize: "14px",
                  }}
                  className="align-middle"
                >
                  No of Test Cases
                  <span onClick={() => { sorting(testsuitelogs._id) || setExpand(!expand) }}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th
                  style={{
                    borderRight: "2px solid #805382",                 
                    fontSize: "14px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                 Last Run Status
                </th>
                <th
                  style={{
                    borderRight: "2px solid #805382",
                    fontSize: "14px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                  Version
                 <span onClick={() => { sorting(testsuitelogs._id) || setExpand(!expand) }}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>

                <th
                  style={{
                    borderRight: "2px solid #805382",                  
                    fontSize: "14px",
                    width:"13%"
                  }}
                  className="align-middle"
                >
                  Last Executed
                  <span onClick={() => { sorting(testsuitelogs._id) || setExpand(!expand) }}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th style={{  width:"23%"}}>
                  <span className="TC-action ">Actions</span>
                  <img src={filter} className="TC-filter" alt="filter icon" />
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {testsuitelogs.map((testsuite, i) => {
                // const n = i + 1;
                // const num = `TS-${n}`;
                //const testsuite.testcase_count = testsuite.test_cases.length;

                return (
                  <>
                 { (testsuite.last_run_status === 'Pending' || testsuite.last_run_status === 'Passed' ||testsuite.last_run_status === 'failed' || testsuite.last_run_status === 'queued' || testsuite.last_run_status === 'in progress' || testsuite.testcase_count > 0) && 
                 <tr key={i}>
                    <td>
                      <input
                        type="checkbox"
                        style={{ margin: "6px", zoom: "1" }}
                      />
                      {`TS${testsuite.testsuite_id +100 }`}
                    </td>
                    <td>{testsuite.testcase_count ? testsuite.test_suite_id.name : testsuite.name}</td>
                    <td>{ testsuite.testcase_count ? testsuite.testcase_count : testsuite.test_cases.length}</td>
                    <td
                      style={{
                        color:
                          testsuite.last_run_status === "passed" ||
                          (runvalue === "passed" && testsuite._id === unid) ||
                          (runvalue === "failed" && testsuite._id === unid)
                            ? "#7EB965"
                            : testsuite.last_run_status === "failed"
                            ? "#DC3545"
                            : "#eab676",
                        fontWeight: "bold",

                        
                      }}
                    >
                      {/* {testsuite.last_run_status} */}
                      {/* {`${
                        (testsuite.passed_test_cases.length / testsuite.testcase_count) * 100
                      }
                        ${
                          (testsuite.failed_test_cases.length / testsuite.testcase_count) * 100
                        }
                        ${
                          ((testsuite.testcase_count -
                            (testsuite.passed_test_cases.length +
                              testsuite.failed_test_cases.length)) /
                            testsuite.testcase_count) *
                          100
                        }
                        `} */}
                      {testsuite.last_run_status === "Pending" || testsuite.last_run_status === 'queued' || testsuite.last_run_status === 'in progress'  ? (
                        testsuite.last_run_status
                          ) : (
                             
                              <>
                                <span>{testsuite.last_run_status}</span>
                                
                        <div className="d-flex px-4">
                          <Tippy
                            placement="top"
                            theme="light"
                            className="tippy-tooltip"
                            content={
                              testsuite.passed_test_cases !== undefined
                                ? `Passed (${Math.round(
                                    (testsuite.passed_test_cases.length /
                                      testsuite.testcase_count) *
                                      100
                                  )}%)`
                                : "0%"
                            }
                          >
                            <div
                              className="value"
                              style={{
                                backgroundColor: "#7EB965",
                                height: "16px",
                                width:
                                  testsuite.passed_test_cases !== undefined
                                    ? (testsuite.passed_test_cases.length /
                                        testsuite.testcase_count) *
                                        100 +
                                      "%"
                                    : 0 + "%",
                              }}
                              key={i}
                            >
                              <span style={{display:'none'}}>{exedate}</span>
                            </div>
                          </Tippy>
                          <Tippy
                            placement="top"
                            theme="light"
                            className="tippy-tooltip"
                            content={
                              testsuite.failed_test_cases !== undefined
                                ? `Failed (${Math.round(
                                    (testsuite.failed_test_cases.length /
                                      testsuite.testcase_count) *
                                      100
                                  )}%)`
                                : "0%"
                            }
                          >
                            <div
                              className="value"
                              style={{
                                backgroundColor: "#DC3545",
                                height: "16px",
                                width:
                                  testsuite.failed_test_cases !== undefined
                                    ? (testsuite.failed_test_cases.length /
                                        testsuite.testcase_count) *
                                        100 +
                                      "%"
                                    : 0 + " %",
                              }}
                              key={i}
                            ></div>
                          </Tippy>
                          <Tippy
                            placement="top"
                            theme="light"
                            className="tippy-tooltip"
                            content={
                              testsuite.passed_test_cases !== undefined &&
                              `No Run (${Math.round(
                                ((testsuite.testcase_count -
                                  (testsuite.passed_test_cases.length +
                                    testsuite.failed_test_cases.length)) /
                                  testsuite.testcase_count) *
                                  100
                              )}%)`
                            }
                          >
                            <div
                              className="value"
                              style={{
                                backgroundColor: "#CCCCCC",
                                height: "16px",

                                width:
                                  testsuite.passed_test_cases !== undefined &&
                                  ((testsuite.testcase_count -
                                    (testsuite.passed_test_cases.length +
                                      testsuite.failed_test_cases.length)) /
                                    testsuite.testcase_count) *
                                    100 +
                                    "%",
                              }}
                              key={i}
                            ></div>
                          </Tippy>
                            </div>
                        </>
                      )}
                    </td>
                    <td>v1</td>
                    {/* <td>{testsuite.updated_by.Ad_username}</td> */}
                    <td style={{ width: "200px" }}>
                      {testsuite.last_run_status === "Pending" || testsuite.last_run_status === 'queued' || testsuite.last_run_status === 'in progress'
                        ? "------" :
                        moment(testsuite.updated_at).format("D MMM h:mm a") }     
                    </td>
                    <td>
                      <div className="TS-action-icons justift-content-center">
                        <ScheduleTestsuite
                          id={testsuite._id}
                          status={status}
                          getTestsuites={getTestsuitesByProjectId}                        
                        />
                        <Tippy
                          placement="top"
                          theme="light"
                          content={testsuite.last_run_status === "Pending" || testsuite.last_run_status === "queued" || testsuite.last_run_status === "in progress" ? "Please Execute the Test suite" : "Report"}
                          className="tippy-tooltip"
                        >
                          <img
                            src={report}
                            style={{ cursor: "pointer" }}
                            alt="report icon"
                            onClick={() =>
                              navigate(
                                `/qe/testsuite/reportTable/${testsuite.test_suite_id._id}`
                              )
                            }
                          />
                        </Tippy>
                        <button
                          style={{ background: "none", border: "none" }}
                          onClick={() => getId(testsuite)}
                        >
                          <RunTestsuite
                            id={testsuite.test_suite_id ? testsuite.test_suite_id._id : testsuite._id}
                            status={status}
                            getTestsuites={getTestsuitesByProjectId}
                          />
                        </button>
                        <span 
                        // onClick={() => editTestsuite(testsuite)}
                        >
                          <EditTsButton editTest={testsuite} />
                        </span>

                        <DeleteTsButton id={testsuite.test_suite_id ? testsuite.test_suite_id._id : testsuite._id} />
                      </div>
                    </td>
                  </tr>}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div> 
      </>
    );
  }

  return (
    <div>
      <Header />

      <div>
        {/*Here start adding the test suite drop options */}

        {testlist}
      </div>
      {/* <Paginate /> */}
    </div>
  );
}
