import { Table } from "react-bootstrap";
import "./AdminUpcomingTest.css";

function UpcomingTest() {
  return (
    <>
      <div className="ad-table_content">
        <Table className="ad-table-upcomingtest">
          <thead>
            <tr>
              <th scope="col" colSpan={1}>
                Test Suite
              </th>
              <th scope="col" colSpan={1}>
                Schedule Frequency
              </th>
              <th scope="col" colSpan={1}>
                Date & Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={1}>TS102_Verify sign in-athendication</td>
              <td colSpan={1}>Daily</td>
              <td colSpan={1}>22 jul 9.00AM</td>
            </tr>
            <tr>
              <td colSpan={1}>TS103_Verify dashboard page</td>
              <td colSpan={1}>Daily, Weekly</td>
              <td colSpan={1}>22 jul 9.00AM</td>
            </tr>
            <tr>
              <td colSpan={1}>TS104_Verfy the call distribution page</td>
              <td colSpan={1}>Daily, Monthly</td>
              <td colSpan={1}>22 jul 9.00AM</td>
            </tr>
            <tr>
              <td colSpan={1}>TS104_Verfy the call distribution page</td>
              <td colSpan={1}>Daily, Monthly</td>
              <td colSpan={1}>22 jul 9.00AM</td>
            </tr>
            <tr>
              <td colSpan={1}>TS104_Verfy the call distribution page</td>
              <td colSpan={1}>Daily, Monthly</td>
              <td colSpan={1}>22 jul 9.00AM</td>
            </tr>
            <tr>
              <td colSpan={1}>TS104_Verfy the call distribution page</td>
              <td colSpan={1}>Daily, Monthly</td>
              <td colSpan={1}>22 jul 9.00AM</td>
            </tr>
            <tr>
              <td colSpan={1}>TS104_Verfy the call distribution page</td>
              <td colSpan={1}>Daily, Monthly</td>
              <td colSpan={1}>22 jul 9.00AM</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default UpcomingTest;
