import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./components/SignIn/SignIn";
import TestcaseList from "./components/TestCase/TestcaseList/TestcaseList";
import CreateTestcase from "./components/TestCase/CreateTestcase/CreateTestcase";
import GenerateTestcase from "./components/TestCase/GenerateTestcase/GenerateTestcase";
import EditTestcase from "./components/TestCase/EditTestcase/EditTestcase";
import CloneTestcase from "./components/TestCase/CloneTestcase/CloneTestcase";
import TestsuiteList from "./components/TestSuite/TestsuiteList/TestsuiteList";
import DashboardQE from "./components/Dashboard/DashboardQE/Dashboard";
import ProjectList from "./components/Admin/Project/ProjectList/ProjectList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplicationList from "./components/Admin/Application/ApplicationList/ApplicationList";
import DashboardAdmin from "./components/Dashboard/DashboardAdmin/DashboardAdmin";
import ReportTable from "./components/TestSuite/Report/ReportTable";
import Report from "./components/Reports/TestsuiteReport/ReportTable/ReportTable";
import TestReport from "./components/DashboardComponents/TestReport/TestReport";
import Schedule from "./components/Schedule/Schedule";
//import EditSchedule from "./components/Schedule/EditSchedule/EditSchedule";
import StakeHolderList from "./components/Admin/Stakeholder/Stakeholder List/StakeHolderList";
import TestcaseReport from "./components/TestCase/ExecutionReport/Report";
import TsReport from "./components/Reports/TestcaseReport/TestcaseReport";
import UserList from "./components/Admin/Users/User List/UserList";



// import AuthVerify from "./AuthVerify";
// import { useNavigate } from "react-router";

function App() {
  // const [sessionToken, setSessionToken] = useState(false);
  const token = localStorage.getItem("access-key");
  const role = localStorage.getItem("role-name");
  const project_id = localStorage.getItem("project-id");
  // const navigate = useNavigate();

  // const runLogoutTimer = (timer) => {
  //   setTimeout(() => {
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     // handleLogout();
  //   }, timer);
  // };

  // useEffect(() => {
  //   if (token) {
  //     let decoded = jwt_decode(token);
  //     const date_to = new Date(decoded.exp * 1000);
  //     const cur_date = new Date(Date.now());
  //     console.log("diff", date_to.getTime() - cur_date.getTime());
  //     runLogoutTimer(date_to.getTime() - cur_date.getTime());
  //   }
  // }, []);

  // useEffect(() => {
  // if (token) {
  //   let decoded = jwt_decode(token);
  //   const payload = getPayload(token);
  //   const expiration = new Date(payload.exp);
  //   const now = new Date();
  //   const oneMin = 1000 * 60 * 0.5;
  //   if (expiration.getTime() - now.getTime() < oneMin) {
  //     setSessionToken(false);
  //     handleLogout();
  //     console.log("JWT has expired or will expire soon");
  //   } else {
  //     setSessionToken(true);
  //     console.log("JWT is valid for less than 30 seconds", payload);
  //   }
  // const token_date = new Date(decoded.exp * 1000);
  // const date_to = new Date(Date.now());
  // if (token_date < date_to) {
  //   setSessionToken(false);
  //   window.location.reload();
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   console.log("token expired");
  //   alert("session Expired");
  //   handleLogout();
  // } else {
  //   setSessionToken(true);
  //   console.log("token found");
  // }
  // console.log(decoded.exp * 1000);
  // }
  // }, []);

  return (
    <div className="App">
      <Router>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeButton={false}
          ltr={true}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          limit={1}
        />
        <Routes>
          <Route
            exact
            path="/"
            element={!token ? <SignIn /> : role === 'Quality Engineer' ? <DashboardQE /> : <DashboardAdmin />}
          />
          {[
            "/qe/dashboard",
            "/qe/dashboard/pr/:context",
            "/qe/dashboard/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <DashboardQE /> : <SignIn />}
              />
            );
          })}

          {/* <Route  
            path="/qe/testcase/:id?"
            element={token ? <TestcaseList /> : <SignIn />}
          /> */}
          {/* <Route path="/qe/testcase">
            <Route path=":id" element={token ? <TestcaseList /> : <SignIn />} />
            <Route path="" element={token ? <TestcaseList /> : <SignIn />} />
          </Route> */}
          {/* <Route
            exact
            path={[
              "/qe/testcase",
              "/qe/testcase/:context",
              "/qe/testcase/:context/:var",
            ]}
            element={token ? <TestcaseList /> : <SignIn />}
          /> */}
          {[
            "/qe/testcase",
            "/qe/testcase/pr/:context",
            "/qe/testcase/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <TestcaseList /> : <SignIn />}
              />
            );
          })}
          <Route
            path="/qe/testcase/create-testcase"
            element={token ? <CreateTestcase /> : <SignIn />}
          />
          <Route
            path="/qe/testcase/generate-testcase"
            element={token ? <GenerateTestcase /> : <SignIn />}
          />
          <Route
            path="/qe/testcase/edit-testcase/:id"
            element={token ? <EditTestcase /> : <SignIn />}
          />
          <Route
            path="/qe/testcase/clone-testcase/:id"
            element={token ? <CloneTestcase /> : <SignIn />}
          />
          <Route
            path={project_id ? `/qe/testsuite/pr/${project_id}` : `/qe/dashboard`}
            element={token ? <TestsuiteList /> : <SignIn />}
          />
          {[
            "/qe/testsuite",
            "/qe/testsuite/pr/:context",
            "/qe/testsuite/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <TestsuiteList /> : <SignIn />}
              />
            );
          })}

          {[
            "/admin/stakeholder",
            "/admin/stakeholder/pr/:context",
            "/admin/stakeholder/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <StakeHolderList /> : <SignIn />}
              />
            );
          })}

          {[
            "/admin/users",
            "/admin/users/pr/:context",
            "/admin/users/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <UserList /> : <SignIn />}
              />
            );
          })}

          <Route
            path="/admin/project"
            element={token ? <ProjectList /> : <SignIn />}
          />
          {[
            "/admin/application",
            "/admin/application/pr/:context",
            "/admin/application/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <ApplicationList /> : <SignIn />}
              />
            );
          })}

          {[
            "/admin/dashboard",
            "/admin/dashboard/pr/:context",
            "/admin/dashboard/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <DashboardAdmin /> : <SignIn />}
              />
            );
          })}
          <Route
            path="/qe/testsuite/reportTable/:id"
            element={token ? <ReportTable /> : <SignIn />}
          />
          <Route
            path="/qe/testReport/:id"
            element={token ? <TestReport /> : <SignIn />}
          />

          {[
            "/qe/schedule",
            "/qe/schedule/pr/:context",
            "/qe/schedule/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <Schedule /> : <SignIn />}
              />
            );
          })}
          <Route
            path="/qe/testsuite/report"
            element={token ? <ReportTable /> : <SignIn />}
          />
          <Route
            path="/qe/testcase/report/:testId"
            element={token ? <TestcaseReport /> : <SignIn />}
          />
          {[
            "/qe/testcase/reports",
            "/qe/testcase/reports/:proId",
          ].map((path) => {
            return (
              <Route
                path={path}
                element={token ? <TsReport /> : <SignIn />}
              />
            );
          })}

          {[
            "/qe/testsuite/report/table",
            "/qe/testsuite/report/table/:proId",
          ].map((path) => {
            return (
              <Route
                path={path}
                element={token ? <Report /> : <SignIn />}
              />
            );
          })}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
