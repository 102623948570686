import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Tippy from "@tippyjs/react";
import "./EditSchedule.css";
import edit from ".././.././../assets/Icons/Edit.svg";
import Form from "react-bootstrap/Form";
import chrome from "./../../../assets/Icons/Chrome.svg";
import safari from "./../../../assets/Icons/Safari.svg";
import firefox from "./../../../assets/Icons/Firefox.svg";
import edge from "./../../../assets/Icons/Edge.svg";
import apple from "./../../../assets/Icons/iOS.svg";
import android from "./../../../assets/Icons/Android.svg";
// import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { API } from "../../../global";
import Multiselect from "multiselect-react-dropdown";


let arrList = [];
let mailList = [];

const options = [
  {
    name: "Disable backdrop",
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    // setCurentId(false)
    setSchedule_id(editScheduleData._id)
    setEnvironment(editScheduleData.environment)
    setVersion(editScheduleData.version)
    setFrequency(editScheduleData.frequency)
    setSelectDay(editScheduleData.day)
    SetSelectSession(editScheduleData.session)
    setTime(editScheduleData.time)
    setDate(editScheduleData.start_date)
    setTestsuite_id(editScheduleData.testsuite_id)
    
  };
  const toggleShow = () => setShow((s) => !s);
  let fff = { ...props }
  let editScheduleData = fff.editData;
  let getList = fff.getSchedule;
  let stakeList = [];
  stakeList = fff.stakeData;
  // const [projectlist, setProjectlist] = useState([]);
  // const [projectId, setProjectId] = useState("");
  const [schedule_id, setSchedule_id] = useState(editScheduleData._id)
  const [environment, setEnvironment] = useState(editScheduleData.environment);
  const [version, setVersion] = useState(editScheduleData.version);
  // const [schedule, setSchedule] = useState({});
  // const [tenant_id, setTenant_id] = useState('');
  const [frequency, setFrequency] = useState(editScheduleData.frequency);
  const [day, setSelectDay] = useState(editScheduleData.day)
  const [session, SetSelectSession] = useState(editScheduleData.session)
  const [time, setTime] = useState(editScheduleData.time);
  const [date, setDate] = useState(editScheduleData.start_date)
  const [testsuite_id, setTestsuite_id] = useState(editScheduleData.testsuite_id);
  const [testsuiteList, setTestsuiteList] = useState([]);
  // const [scheduleList, setScheduleList] = useState([]);
  const [scheduleStake, setScheduleStake] = useState([]);
  // const [checkTS, setCheckTS] = useState(editScheduleData.stakeholder);
  // const [selectedValue, setSelectedValue] = useState([]);
  // const [currentId, setCurentId] = useState(false);
  // console.log(editScheduleData.stakeholder[0], "test");
  // console.log(stakeList, "list");
  let finalArr = [];
  let ray = [];
  // const stakes = stakeList.filter((a, i, ar) => ar[i].mail == editScheduleData.stakeholder[i])
  // console.log(stakes, "stake test");
  // console.log(editScheduleData.stakeholder, "stake test");
  
  editScheduleData.stakeholder.forEach((d, i) => {
    stakeList.forEach((s, i) => {
      if (d === s.mail) {
        finalArr.push(s)
      }
    })

    // if (d.mail == editScheduleData.stakeholder[i]) {
    //   console.log(d);
    // }
    // console.log(editScheduleData.stakeholder[i]);
    // console.log(editScheduleData.stakeholder);
  })

  useEffect(() => {
    getTestSuite();
    getStakeholder();
    setSchedule_id(editScheduleData._id)
    // eslint-disable-next-line
  }, [editScheduleData]);

  const handleEnvironment = (event) => {
    setEnvironment(event.target.value);
  };
  const handleVersion = (event) => {
    setVersion(event.target.value);
  };
  const handleFrequency = (event) => {
    setFrequency(event.target.value);
    if (event.target.value === 'Weekly') {
      setSelectDay('Monday');
      SetSelectSession('')
    } if (event.target.value === 'Monthly') {
      setSelectDay('');
      SetSelectSession('Start of Month')
    }
    if (event.target.value === 'Daily') {
      setSelectDay('');
      SetSelectSession('')
    }
  };
  //tests/testsuites/schedule/getid/100
  const handleSuiteId = (event) => {
    setTestsuite_id(event.target.value)
  }

  const handleDay = (event) => {
    setSelectDay(event.target.value);
  };
  const handleSession = (event) => {
    SetSelectSession(event.target.value);
  };
  // const checkList = (e) => {
  //   let arr = [...checkTS];
  //   const value = e;
  //   const index = arr.findIndex((val) => val === value);
  //   if (index > -1) {
  //     arr = [...arr.slice(0, index)];
  //   } else {
  //     arr.push(value);
  //   }
  //   setCheckTS(arr);
  // };

  const editSchedule = () => {
    if (arrList.length !== 0) {
      finalArr = [];     // let stakemem = [...arrList, ...finalArr];
      mailList = [];
      ray = [];
      arrList.forEach((ar, i) => {
        if (mailList[i] !== ar.mail)
          mailList.push(ar.mail);
      })
      ray = mailList.filter((l, i, a) => a.indexOf(l) === i);
    }
    else {
      mailList = [];
      ray = [];
      let stakemem = [...arrList, ...finalArr];
      // let ray = stakemem.filter((l, i, a) => a.indexOf(l) == i);
      stakemem.forEach((ar, i) => {
        if (mailList[i] !== ar.mail)
          mailList.push(ar.mail);
      })
      ray = mailList.filter((l, i, a) => a.indexOf(l) === i);
    }

    const scheduleObj = {
      start_date: date,
      frequency: frequency,
      time: time,
      day: day,
      session: session,
      testsuite_id: testsuite_id,
      environment: environment,
      version: version,
      stakeholder: ray,
      created_by: localStorage.getItem('user-id'),
      updated_by: localStorage.getItem('user-id')
    }

    fetch(`${API}/tests/testsuites/schedule/${schedule_id}`, {
      method: "PUT",
      body: JSON.stringify(scheduleObj),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then(() => {
      getList()
      ray = []
      // getApplication()
      // setCurentId(true)
      handleClose()
    }).catch((err) => {
      console.log(err);
    })
  }

  const getStakeholder = () => {
    // setLoading(true)
    fetch(`${API}/admin/stakeholder`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        setScheduleStake(pro.data)


        // setSelectedValue(stakes);
      })
    // .then(() => setLoading(false))
  };

  const getTestSuite = () => {

    fetch(
      `${API}/tests/testsuites/`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        app.data.length > 0 ? setTestsuiteList(app.data) : setTestsuiteList([]);
      })
  }

  // const getApplication = () => {
  //   fetch(
  //     `${API}/tests/testsuites/schedule/getid/${currentId ? fff.scheduleId : fff.scheduleId + 1}`,
  //     {
  //       method: "GET",
  //       // headers: {
  //       //   authorization: `${localStorage.getItem("access-key")}`,
  //       // },
  //     }
  //   )
  //     .then((val) => val.json())
  //     .then((app) => {
  //       app.data.length > 0 ? setScheduleList(app.data) : setScheduleList([]);
  //     })
  // }

  return (
    <>
      <Tippy
        placement="top"
        className="tippy-tooltip"
        theme="light"
        content="Edit"
      >
        <img
          src={edit}
          onClick={toggleShow}
          style={{ cursor: "pointer" }}
          alt="edit icon"
        />
      </Tippy>

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="CSH-Canvas-edit-head"
      >
        <Offcanvas.Body>
          <Offcanvas.Title className="CSH-edit mt-4">
            Edit Schedule
          </Offcanvas.Title>
          <Form.Group>
            <Form.Label className="edit-label mt-4">
              Select Test Suite
            </Form.Label>
            <Form.Select
              type="text"
              value={testsuite_id}
              className="select-edit-CSH-env"
              required
              onChange={handleSuiteId}
            >{
                testsuiteList.map(list => (
                  <option value={list._id}>{list.name}</option>
                ))
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mt-3" value={environment}>
            <Form.Label className="CSH-edit-label">
              Select Environment
            </Form.Label>
            <Form.Select
              as="select"
              className="select-edit-CSH-env"
              value={environment}
              onChange={handleEnvironment}
            >
              <option value="Alpha Testing Environment">
                Alpha Testing Environment
              </option>
              <option value="Dev Environment">Dev Environment</option>
              <option value="Cloud Environment">Cloud Environment</option>
              <option value="Performance Testing Environment">
                Performance Testing Environment
              </option>
              <option value="System Integrating Testing(SIT)">
                System Integrating Testing(SIT)
              </option>
              <option value="User Acceptance Testing(UAT)">
                User Acceptance Testing(UAT)
              </option>
              <option value="Security Testing">Security Testing</option>
            </Form.Select>
          </Form.Group>

          <div className="browser-select d-flex mt-3">
            <div className="mt-3">
              <Form.Label htmlFor="basic-url" className="edit-label">
                Select Browser / Mobile OS
              </Form.Label>
              <div className="browser-icon d-flex">
                <img
                  src={chrome}
                  style={{ cursor: "pointer" }}
                  alt="chrome icon"
                />
                <img
                  src={safari}
                  style={{ cursor: "pointer", opacity: '0.3' }}
                  alt="safari icon"
                />
                <img
                  src={firefox}
                  style={{ cursor: "pointer", opacity: '0.3' }}
                  alt="firefox icon"
                />
                <img src={edge} style={{ cursor: "pointer", opacity: '0.3' }} alt="edge icon" />
                <img
                  src={apple}
                  style={{ cursor: "pointer", opacity: '0.3' }}
                  alt="apple icon"
                />
                <img
                  src={android}
                  style={{ cursor: "pointer", opacity: '0.3' }}
                  alt="android icon"
                />
              </div>
            </div>
            <div className="browser-version ms-4 mt-3">
              <Form.Label htmlFor="basic-url" className="browser-label">
                Select Version
              </Form.Label>

              <Form.Group>
                <Form.Select
                  className="version-select d-flex"
                  value={version}
                  onChange={handleVersion}
                  required
                >
                  <option value="Chrome 100">Chrome 100</option>
                  <option value="Chrome 99">Chrome 99</option>
                  <option value="Chrome 98">Chrome 98</option>
                  <option value="Chrome 97">Chrome 97</option>
                  <option value="Chrome 96">Chrome 96</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <Form.Group className="mt-3" value={environment}>
            <Form.Label className="CSH-edit-label">
              Assign StakeHolders
            </Form.Label>

            <Multiselect className="CSH-stake-select"
              displayValue="mail"
              selectedValues={finalArr}
              // onKeyPressFn={function noRefCheck() { }}
              onRemove={function noRefCheck(e) { arrList = e }}

              // onSearch={function noRefCheck() { }}
              onSelect={function noRefCheck(e) {
                arrList = e;
                // setSelectedList([...e, ...selectedList])
              }}
              options={[...scheduleStake]}
              showCheckbox
            />

          </Form.Group>

          <div className="CSH-edit-Date mt-3 d-flex">
            <div className="CSH-DP-head">
              <Form.Label htmlFor="basic-url" className="CSH-datePicker">
                Start Date
              </Form.Label>

              <div className="CSH-DP-input mr-2">
                <Form.Control type="date" id="date" name="date" className="CSH-form" value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
              </div>
            </div>

            <div className="CSH-FR-head d-flex">
              <Form.Group className="w-100">
                <Form.Label htmlFor="basic-url">Frequency</Form.Label>
                <Form.Select
                  className="CSH-edit-version-select d-flex"
                  value={frequency}
                  onChange={handleFrequency}
                >
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </Form.Select>
              </Form.Group>

            </div>
          </div>

          <div className="FR-edit-sec d-flex">
            <Form.Control type="time" id="time" name="time" className="CSH-time" value={time}
              onChange={(event) => setTime(event.target.value)}
            />

            {day !== "" && <Form.Group>
              <Form.Select
                className="CSH-edit-day-version-select d-flex "
                value={day}
                onChange={handleDay}

              >
                <option value="" disabled>select a day</option>
                <option value="Sunday">Sunday</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="thursday">thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
              </Form.Select>
            </Form.Group>}

            {/*Select session if chose month */}

            {session !== "" && <Form.Group>
              <Form.Select
                className="CSH-sess-version-select d-flex"
                value={session}
                onChange={handleSession}
              >
                <option value="" disabled>select a session</option>
                <option value="Start of Month">Start of Month</option>
                <option value="End of Month">End of Month</option>
              </Form.Select>
            </Form.Group>}
          </div>

          <div className="CSH-suite-buttons justify-content-end ml-3">
            <button className="save-CSH" onClick={() => editSchedule()}>Schedule</button>
            <button className="CSH-cancel-ts" onClick={()=> {
              handleClose()
              // setSchedule_id("")
              // setEnvironment("")
              // setVersion("")
              // setFrequency("")
              // setSelectDay("")
              // SetSelectSession("")
              // setTime("")
              // setDate("")
              // setTestsuite_id("")
              // setScheduleStake([])
                          }}>
              Cancel
            </button>
          </div>

          {/* Schedule list */}

          {/* {currentId && <Table responsive="sm" className="mt-5 CSH-list">
            <thead>
              <tr>
                <th colSpan={8}>Frequency</th>
                <th colSpan={2}>Start Date</th>
                <th colSpan={1}>Action</th>
              </tr>
            </thead>
            <tbody>
              {scheduleList.map(list => (
                <tr >
                  <td colSpan={8}>{list.schedule.frequency}</td>
                  <td colSpan={2}>{list.schedule.start_date}</td>
                  <td colSpan={1}>X</td>
                </tr>
              ))

              }

            </tbody>
          </Table>} */}

          {/* <div className="CSH-suite-buttons justify-content-end ml-3">
            <button className="save-CSH" onClick={handleClose}>
              Save
            </button>
          </div> */}
        </Offcanvas.Body >
      </Offcanvas >
    </>
  );
}

export default function EditSchedule({ editData, getSchedule, stakeData }) {



  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          getSchedule={getSchedule}
          editData={editData}
          stakeData={stakeData}
          {...props}
        />
      ))}
    </>
  );
}
